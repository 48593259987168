import { createApi } from '@reduxjs/toolkit/query/react';
import buildQuery, { QueryOptions } from 'odata-query';
import customBaseQuery from './common/customBaseQuery';
import { RoleModel } from '../../models/RoleModel';

type GetRolesResponse = {
  count: number;
  items: RoleModel[]
}

export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getRole: builder.query<RoleModel, string>({
      query(roleName) {
        return {
          url: `user/roles/${roleName}`,
          method: 'GET',
        };
      },
    }),
    getRoles: builder.query<GetRolesResponse, Partial<QueryOptions<RoleModel>>>({
      query(queryData) {
        const queryString = buildQuery(queryData);
        return {
          url: `user/roles${queryString}`,
          method: 'GET',
        };
      }, 
    }),
    updateRole: builder.mutation<RoleModel, any>({
      query(data) {
        const { name, ...body } = data;
        return {
          url: `user/roles/${name}`,
          method: 'PUT',
          body: body
        };
      },
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          rolesApi.util.updateQueryData('getRoles', id, (draft) => {
            Object.assign(draft, patch)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()

          /**
           * Alternatively, on failure you can invalidate the corresponding cache tags
           * to trigger a re-fetch:
           * dispatch(api.util.invalidateTags(['Post']))
           */
        }
      },
    }),
    createRole: builder.mutation<RoleModel, any>({
      query(data) {
        return {
          url: `user/roles`,
          method: 'POST',
          body: data
        };
      },
    }),
    deleteRole: builder.mutation<RoleModel, string | number>({
      query(roleName) {
        return {
          url: `user/roles/${roleName}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetRoleQuery,
  useUpdateRoleMutation,
  useCreateRoleMutation,
  useDeleteRoleMutation,
} = rolesApi;