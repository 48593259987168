import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import FormPageLayout from '../../components/common/FormPageLayout';
import UpdateRoleForm from '../../components/forms/role-form/UpdateRoleForm';
import { useGetRoleQuery, useUpdateRoleMutation } from '../../redux/api/rolesApi';

const EditRole: FC = () => {
    const { roleName } = useParams();
    const { data: role, isLoading, isFetching } = useGetRoleQuery(roleName ?? skipToken, {
        refetchOnMountOrArgChange: true,
    });

    const [updateRole, { isLoading: isUpdating }] = useUpdateRoleMutation();

    return (
            <FormPageLayout backButtonLabel="Ролі">
                <UpdateRoleForm role={role || null} isLoading={isLoading || isFetching || isUpdating} onSubmitValues={updateRole} />
            </FormPageLayout>
    );
};

export default EditRole;
