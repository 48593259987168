import React from 'react';
import CreateWebFooterContentForm from '../../components/forms/content-form/CreateWebFooterContentForm';
import FormPageLayout from '../../components/common/FormPageLayout';
import { useCreateWebFooterContentMutation } from '../../redux/api/webFooterContentApi';
import { useGetWFCCategoryQuery } from '../../redux/api/webFCCategoryApi';
import { WebFooterContentModel } from '../../models/WebFooterContentModel';
import { ROUTE_CONTENT, MODE_WEB_FOOTER_CONTENT } from '../../constants/ContentConstants';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

const CreateWebFooterContent = () => {
    const [createContent, { isLoading }] = useCreateWebFooterContentMutation();
    const { data: categories, isLoading: isCategoryLoading, isFetching } = useGetWFCCategoryQuery();
    const navigate = useNavigate();
    const handleSubmit = async (data: WebFooterContentModel) => {
        try {
            const request = await createContent(data).unwrap();
            if (request.id) {
                navigate(`/${ROUTE_CONTENT}/${MODE_WEB_FOOTER_CONTENT}/${request.id}`);
            }
        } catch (e: any) {
            message.error(e.data.message);
        }
    };

    return (
        <FormPageLayout backButtonLabel="Футер">
            <CreateWebFooterContentForm
                categories={categories}
                isLoading={isLoading || isCategoryLoading || isFetching}
                onSubmit={handleSubmit}
            />
        </FormPageLayout>
    );
};

export default CreateWebFooterContent;
