import {getContainFilterValue} from "./index";

export const getFullNameFilterValue = (initials: string) => {
    return initials ? initials.trim().replaceAll(/\s{2,}/g, ' ').split(' ').map((part) => ({
        or: [
            {'tolower(name)': getContainFilterValue(part)},
            {'tolower(surname)': getContainFilterValue(part)},
            {'tolower(patronymic)': getContainFilterValue(part)},
        ],
    })) : undefined;
}