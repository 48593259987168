import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import {WebFooterContentModel} from "../../models/WebFooterContentModel";

export const webFooterContentApi = createApi({
    reducerPath: 'webFooterContentApi',
    baseQuery: customBaseQuery,
    tagTypes: ['WebFooterContent'],
    endpoints: (builder) => ({
        getWebFooterContent: builder.query<WebFooterContentModel[], void>({
            query() {
                return {
                    url: `business/dictionary/web_footer_content`,
                    method: 'GET',
                };
            },
            providesTags: ['WebFooterContent'],
        }),
        getWebFooterContentById: builder.query<any, string | number>({
            query(id) {
                return {
                    url: `business/dictionary/web_footer_content/${id}`,
                    method: 'GET',
                };
            },
            providesTags: ['WebFooterContent'],
            transformResponse: (response: WebFooterContentModel) => {
                const translations = response.translations?.map(item => {
                    const lang = item?.lang?.code;
                    return {...item, lang}
                })
                return {...response, translations};
            },
        }),
        updateWebFooterContent: builder.mutation<WebFooterContentModel, WebFooterContentModel>({
            query(data) {
                const {id, ...body} = data;
                return {
                    url: `business/dictionary/web_footer_content/${data.id}`,
                    method: 'PUT',
                    body: body
                };
            },
            invalidatesTags: ['WebFooterContent'],
        }),
        createWebFooterContent: builder.mutation<WebFooterContentModel, WebFooterContentModel>({
            query(data) {
                return {
                    url: `business/dictionary/web_footer_content`,
                    method: 'POST',
                    body: data
                };
            },
            invalidatesTags: ['WebFooterContent'],
        }),
        deleteWebFooterContent: builder.mutation<WebFooterContentModel, string | number>({
            query(id) {
                return {
                    url: `business/dictionary/web_footer_content/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['WebFooterContent'],
        }),
    }),
});

export const {
    useGetWebFooterContentQuery,
    useGetWebFooterContentByIdQuery,
    useUpdateWebFooterContentMutation,
    useCreateWebFooterContentMutation,
    useDeleteWebFooterContentMutation,
} = webFooterContentApi;