import { Button, Col, Row, Table, TablePaginationConfig, TableProps, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { QueryOptions } from 'odata-query';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getTopSkip } from '../../helpers';
import getTableActionColumn from '../../helpers/getTableActionColumn';
import { RoleModel } from '../../models/RoleModel';
import { useDeleteRoleMutation, useGetRolesQuery } from '../../redux/api/rolesApi';

const getColumns = (pathname: string, onDelete: (value: string | number) => void): ColumnsType<RoleModel> => [
    {
        title: 'Назва',
        dataIndex: 'name',
        key: 'name',
        render: (name: string, record) => <Link to={`${pathname}/${record.name}`}>{name}</Link>,
    },
    {
        title: 'Опис',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Функції',
        dataIndex: 'menuFunctions',
        render: (functions: any[]) => (
            <div>
                {functions.map((item) => (
                    <Tag
                        key={item.id}
                        style={{
                            backgroundColor: 'rgba(56, 83, 92, 0.8)',
                            color: 'rgb(255, 255, 255)',
                            borderColor: 'rgba(56, 83, 92, 0.85)',
                            margin: '2px 8px 2px 0',
                        }}>
                        {item.name}
                    </Tag>
                ))}
            </div>
        ),
        width: '73%',
    },
    getTableActionColumn({
        options: { delete: true },
        onDelete,
        rowKey: 'name',
    }),
];

const defaultUserQuery = {
    orderBy: 'createDate desc',
    count: true,
};

const Roles: FC = () => {
    const { pathname } = useLocation();
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });

    const queryData = useMemo<Partial<QueryOptions<RoleModel>>>(
        () => ({
            ...defaultUserQuery,
            ...getTopSkip(pagination.pageSize, pagination.current),
            // filter: filters,
        }),
        [pagination /* , filters */],
    );

    const { data, isLoading, isFetching, refetch } = useGetRolesQuery(queryData);
    const roles: any[] = data?.items || [];
    const count = data?.count;

    useEffect(() => {
        refetch();
    }, [])

    const [deleteRole] = useDeleteRoleMutation();

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    const handleTableChange: TableProps<RoleModel>['onChange'] = (newPagination, _filters, _sorter) => {
        setPagination(newPagination);
    };

    const handleDelete = (name: string | number) => {
        deleteRole(name);
    };

    return (
        <Row>
            <Col span={24}>
                <Row justify="end">
                    <Link to={`${pathname}/new`}>
                        <Button type="primary">Створити роль</Button>
                    </Link>
                    <Button onClick={refetch}>Оновити</Button>
                </Row>
            </Col>
            {/* <Col span={24}>
                <UserFilters setFilters={handleFilters} />
            </Col> */}
            <Table<RoleModel>
                className="main-table"
                rowKey="id"
                loading={isLoading || isFetching}
                columns={getColumns(pathname, handleDelete)}
                dataSource={roles}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </Row>
    );
};

export default Roles;
