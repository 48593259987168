import { LeftOutlined } from "@ant-design/icons";
import { Button, ButtonProps } from "antd";
import { FC, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

const BackButton: FC<ButtonProps> = ({ children, ...props}) => {
    const location = useLocation();
    const previousPath = useMemo(() => location.pathname.substring(0, location.pathname.lastIndexOf('/')), [location.pathname]);

    return <Link to={previousPath} /* reloadDocument */><Button type="link" {...props}><LeftOutlined />{children}</Button></Link>
}

export default BackButton;