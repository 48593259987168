export const defaultFunctions = [
  // {
  //   children: [],
  //   icon: null,
  //   id: -1,
  //   isActive: true,
  //   name: 'Загальна панель',
  //   ord: 999,
  //   path: 'dashboard',
  //   code: 'dashboard',
  // },
];
