import React from 'react';
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import TabWebContent from './TabWebContent';
import TabWebFooterContent from './TabWebFooterContent';
import { MODE_WEB_CONTENT, MODE_WEB_FOOTER_CONTENT } from '../../constants/ContentConstants';

const collection = [
    {
        label: `Контент`,
        key: MODE_WEB_CONTENT,
        children: <TabWebContent />,
    },
    {
        label: `Футер`,
        key: MODE_WEB_FOOTER_CONTENT,
        children: <TabWebFooterContent />,
    },
];

const Content = () => {
    const { id } = useParams();
    const mode = id ?? MODE_WEB_CONTENT;

    return (
        <div>
            <Tabs defaultActiveKey={mode} items={collection} />
        </div>
    );
};

export default Content;
