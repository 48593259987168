import { createApi } from '@reduxjs/toolkit/query/react';
import buildQuery, { QueryOptions } from 'odata-query';
import customBaseQuery from './common/customBaseQuery';
import { NewsModel, NewsTranslationModel } from '../../models/NewsModel';

type GetNewsResponse = {
    count: number;
    items: NewsModel[]
}

export const newsApi = createApi({
    reducerPath: 'newsApi',
    baseQuery: customBaseQuery,
    tagTypes: ['News'],
    endpoints: (builder) => ({
        getOneNews: builder.query<NewsModel, string | number>({
            query(roleId) {
                return {
                    url: `business/news/${roleId}`,
                    method: 'GET',
                };
            },
            providesTags: ['News'],
        }),
        getNews: builder.query<GetNewsResponse, Partial<QueryOptions<NewsModel>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: `business/news${queryString}`,
                    method: 'GET',
                };
            },
            providesTags: ['News'],
        }),
        generateLinkName: builder.mutation<NewsTranslationModel, any>({
            query(data) {
                return {
                    url: `business/news/translit`,
                    method: 'POST',
                    body: data
                };
            },
        }),
        updateNews: builder.mutation<NewsModel, any>({
            query(data) {
                const {id, ...body} = data;
                return {
                    url: `business/news/${id}`,
                    method: 'PUT',
                    body: body
                };
            },
            invalidatesTags: ['News'],
        }),
        createNews: builder.mutation<NewsModel, any>({
            query(data) {
                return {
                    url: `business/news`,
                    method: 'POST',
                    body: data
                };
            },
            invalidatesTags: ['News'],
        }),
    }),
});

export const {
    useGetNewsQuery,
    useGetOneNewsQuery,
    useGenerateLinkNameMutation,
    useUpdateNewsMutation,
    useCreateNewsMutation,
} = newsApi;