import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ConstructionWorkStatuses } from '../../constants/enums/ConstructionWorkStatuses';
import { DamageCategories } from '../../constants/enums/DemageCategories';
import { LegalDocumentsType } from '../../constants/enums/LegalDocumentsType';
import { RequestStatuses } from '../../constants/enums/RequestStatuses';
import { RequestTypes } from '../../constants/enums/RequestTypes';
import { SurveyActRecommendationCodes } from '../../constants/enums/SurveyActRecommendationCodes';
import { CommonDictionaryModel } from '../../models/CommonDictionaryModel';
import { DictionaryAddressModel } from '../../models/DictionaryAddressModel';
import customBaseQuery from './common/customBaseQuery';
import { prepareAuthHeader } from './common/helpers';

const BASE_URL = process.env.REACT_APP_BASE_URL as string;

export const dictionariesApi = createApi({
    reducerPath: 'dictionariesApi',
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getLegalDocumentTypes: builder.query<CommonDictionaryModel<LegalDocumentsType>[], void>({
            query() {
                return {
                    url: `business/dictionary/legal_document_types`,
                    method: 'GET',
                };
            },
        }),
        getDamageCategories: builder.query<CommonDictionaryModel<DamageCategories>[], void>({
            query() {
                return {
                    url: `business/dictionary/damage_categories`,
                    method: 'GET',
                };
            },
        }),
        getRequestTypes: builder.query<CommonDictionaryModel[], void>({
            query() {
                return {
                    url: `business/dictionary/request_types`,
                    method: 'GET',
                };
            },
        }),
        getSurveyActRecommendations: builder.query<CommonDictionaryModel<SurveyActRecommendationCodes>[], void>({
            query() {
                return {
                    url: `business/dictionary/survey_act_recommendations`,
                    method: 'GET',
                };
            },
        }),
        getConstructionWorkStatuses: builder.query<CommonDictionaryModel<ConstructionWorkStatuses>[], void>({
            query() {
                return {
                    url: `business/dictionary/construction_works_statuses`,
                    method: 'GET',
                };
            },
        }),
        getRequestStatuses: builder.query<CommonDictionaryModel<RequestStatuses>[], void>({
            query() {
                return {
                    url: `business/dictionary/request_statuses`,
                    method: 'GET',
                };
            },
        }),

        getLookingForCategories: builder.query<CommonDictionaryModel[], void>({
            query() {
                return {
                    url: `business/dictionary/looking_for_category`,
                    method: 'GET',
                };
            },
        }),
        getLookingForTypes: builder.query<CommonDictionaryModel[], void>({
            query() {
                return {
                    url: `business/dictionary/looking_for_types`,
                    method: 'GET',
                };
            },
        }),
        getCountries: builder.query<CommonDictionaryModel[], void>({
            query() {
                return {
                    url: `business/dictionary/countries`,
                    method: 'GET',
                };
            },
        }),
        getGender: builder.query<CommonDictionaryModel[], void>({
            query() {
                return {
                    url: `business/dictionary/gender`,
                    method: 'GET',
                };
            },
        }),
        getMilitaryFormation: builder.query<CommonDictionaryModel[], void>({
            query() {
                return {
                    url: `business/dictionary/military_formation`,
                    method: 'GET',
                };
            },
        }),
        getMilitaryRank: builder.query<CommonDictionaryModel[], void>({
            query() {
                return {
                    url: `business/dictionary/military_rank`,
                    method: 'GET',
                };
            },
        }),
        getPhysicalConditions: builder.query<CommonDictionaryModel[], void>({
            query() {
                return {
                    url: `business/dictionary/physical_condition`,
                    method: 'GET',
                };
            },
        }),
        getRegions: builder.query<DictionaryAddressModel[], void>({
            query() {
                return {
                    url: `business/regions`,
                    method: 'GET',
                };
            },
        }),
        getCities: builder.query<DictionaryAddressModel[], void>({
            query(regionId) {
                return {
                    url: `business/citys/${regionId}`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const {
    useGetLegalDocumentTypesQuery,
    useGetDamageCategoriesQuery,
    useGetRequestTypesQuery,
    useGetSurveyActRecommendationsQuery,
    useGetConstructionWorkStatusesQuery,
    useGetRequestStatusesQuery,

    useGetLookingForCategoriesQuery,
    useGetLookingForTypesQuery,
    useGetCountriesQuery,
    useGetGenderQuery,
    useGetMilitaryFormationQuery,
    useGetMilitaryRankQuery,
    useGetPhysicalConditionsQuery,
    useGetRegionsQuery,
    useGetCitiesQuery,
} = dictionariesApi;
