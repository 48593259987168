import { Button, Form, message, Spin } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { NamePath } from 'antd/lib/form/interface';
import moment from 'moment';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateUpdateRequestModel, RequestModel } from '../../../models/RequestModel';
import RequestForm from './RequestForm';

interface UpdateRequestFormProps {
    request: RequestModel | null;
    isLoading: boolean;
    viewMode?: boolean;
    onSubmitValues: (data: CreateUpdateRequestModel) => any;
}

const UpdateRequestForm: FC<UpdateRequestFormProps> = ({ isLoading, request, viewMode = true, onSubmitValues }) => {
    const [form] = useForm<CreateUpdateRequestModel>();
    const useFormWatch = (name: NamePath) => useWatch(name, form);
    const navigate = useNavigate();

    useEffect(() => {
        if (request) {
            form.setFieldsValue({
                ...request,
                dateOfBirth: request?.dateOfBirth ? moment(request.dateOfBirth) : null,
                dateOfLossCommunication: request?.dateOfLossCommunication ? moment(request.dateOfLossCommunication) : null,
            } as any);
        }
    }, [request, form]);

    const onFinish = (values: CreateUpdateRequestModel) => {
        if (request) {
            const finalNews: CreateUpdateRequestModel = {
                ...values,
                id: request.id,
                ...values.dateOfBirth ? {dateOfBirth: new Date(moment(values.dateOfBirth).format('YYYY-MM-DD'))} : {},
                ...values.dateOfLossCommunication ? {dateOfLossCommunication: new Date(moment(values.dateOfLossCommunication).format('YYYY-MM-DD'))} : {},
            };
            onSubmitValues(finalNews)
                .unwrap()
                .then(() => {
                    navigate('/requests', { replace: true });
                })
                .catch((error: any) => {
                    message.error(error.data.message);
                });
        }
    };

    return (
        <Spin spinning={isLoading}>
            <Form<CreateUpdateRequestModel>
                form={form}
                labelCol={{ span: 6 }}
                disabled={viewMode}
                layout="horizontal"
                onFinish={onFinish}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                Запит № {request?.id}
                <RequestForm request={request} onSubmitValues={onFinish} formWatch={useFormWatch} viewMode={viewMode} />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default UpdateRequestForm;
