import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import {WebFCCategoryModel} from "../../models/WebFCCategoryModel";

export const webFCCategoryApi = createApi({
    reducerPath: 'webFCCategoryApi',
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getWFCCategory: builder.query<WebFCCategoryModel[], void>({
            query() {
                return {
                    url: `business/dictionary/web_footer_content_categories`,
                    method: 'GET',
                };
            },
        }),
    }),
});

export const {
    useGetWFCCategoryQuery,
} = webFCCategoryApi;