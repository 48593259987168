import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';

const BASE_URL = process.env.REACT_APP_BASE_URL as string;

export interface FileMetaDataModel {
    contentType: string;
    id: string;
    name: string;
}

export const filesApi = createApi({
    reducerPath: 'filesApi',
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getFileDataById: builder.query<FileMetaDataModel, string>({
            query(fileId) {
                return {
                    url: `storage/files/${fileId}/metadata`,
                    method: 'GET',
                };
            },
        }),
        getBulkFilesByIds: builder.mutation<any, any>({
            query(data) {
                return {
                    url: `storage/files/metadata/bulk`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        deleteFile: builder.mutation<void, string>({
            query(fileId) {
                return {
                    url: `storage/files/${fileId}`,
                    method: 'DELETE',
                };
            },
        }),
    }),
});

// Getting file is not implemented by Redux Toolkit Query API
// to prevent placing file in the cache
// see: https://github.com/reduxjs/redux-toolkit/issues/1522
export const getFileById = (id: string) => {
    return fetch(`${BASE_URL}storage/files/${id}`).then((response) => response.blob());
};

export const createFile = (file: FormData) => {
    return fetch(`${BASE_URL}storage/files`, {
        method: 'POST',
        body: file,
    }).then((response) => response.json());
};

export const {
    useGetFileDataByIdQuery,
    useLazyGetFileDataByIdQuery,
    useDeleteFileMutation,

    useGetBulkFilesByIdsMutation,
} = filesApi;
