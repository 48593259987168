import { Checkbox, Form, Input, Select } from 'antd';
import { FC } from 'react';
import { useGetRolesQuery } from '../../../redux/api/rolesApi';
import moment from 'moment';

interface UserDataFieldsProps {
    disableEmail?: boolean;
    isShowLock?: boolean;
    lockedDate?: string | null;
}

const UserDataFields: FC<UserDataFieldsProps> = ({ disableEmail, isShowLock = true, lockedDate }) => {
    const {
        data: roles,
        isLoading: rolesIsLoading,
        isFetching: rolesIsFetching,
    } = useGetRolesQuery({
        filter: {},
        orderBy: 'description asc',
    });

    return (
        <>
            <Form.Item name="email" rules={[{ required: true }]}>
                <Input placeholder="Ім’я користувача" disabled={disableEmail} />
            </Form.Item>
            <Form.Item name="phone">
                <Input placeholder="Телефон" />
            </Form.Item>
            <Form.Item name="firstName" rules={[{ required: true }]}>
                <Input placeholder="Ім’я" />
            </Form.Item>
            <Form.Item name="lastName">
                <Input placeholder="Прізвище" />
            </Form.Item>
            <Form.Item name="roles">
                <Select
                    mode="multiple"
                    placeholder="Оберіть ролі"
                    style={{ width: '100%' }}
                    fieldNames={{ value: 'name', label: 'description' }}
                    options={roles as any}
                    loading={rolesIsLoading || rolesIsFetching}
                />
            </Form.Item>
            {isShowLock && (
                <Form.Item name="locked" valuePropName="checked">
                    <Checkbox>
                        Заблоковано{!!lockedDate ? ` (${moment(lockedDate).format('DD.MM.YYYY')})` : ''}
                    </Checkbox>
                </Form.Item>
            )}
        </>
    );
};

export default UserDataFields;
