import React, { FC } from 'react';
import { Card, Form, Input, Select } from 'antd';
import { WebFooterContentModel, WebFooterContentTranslation } from '../../../models/WebFooterContentModel';
import SunEditor from 'suneditor-react';
import { Langs } from '../../../constants/enums/Langs';
import { NamePath } from 'antd/lib/form/interface';
import { WebFCCategoryModel } from '../../../models/WebFCCategoryModel';
import {
    CONTENT_TYPES,
    WEB_FOOTER_CONTENT_TYPE_HTML,
    WEB_FOOTER_CONTENT_TYPE_LINK,
} from '../../../constants/ContentConstants';

const EDITOR_HEIGHT = '600';
const EDITOR_WIDTH = '740';
const buttonList = [
    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'fontColor'],
    ['table', 'link', 'video'],
    '/',
    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'list'],
    ['fullScreen', 'showBlocks', 'codeView', 'preview'],
];

const inputWidth = {width: 220};
const langs = Object.keys(Langs);

interface WebFCDataFieldsProps {
    categories?: WebFCCategoryModel[];
    translations?: WebFooterContentModel['translations'];
    getFieldValue: (name: NamePath) => any;
    type?: string;
}
const WebFooterContentDataFields: FC<WebFCDataFieldsProps> = ({
    categories = [],
    translations = [],
    getFieldValue,
    type,
}) => (
    <>
        <Form.Item name={'ord'} rules={[{ required: true }]}>
            <Input placeholder={'Ордер'} style={inputWidth} />
        </Form.Item>
        <Form.Item name={'type'} rules={[{ required: true }]}>
            <Select placeholder={'Тип контенту'} style={inputWidth} options={CONTENT_TYPES} />
        </Form.Item>
        <Form.Item name={'category'} rules={[{ required: true }]}>
            <Select
                placeholder={'Категорія'}
                style={inputWidth}
                options={categories?.map((item) => ({
                    value: item?.code,
                    label: item.translations?.[0]?.description,
                }))}
            />
        </Form.Item>
        {langs.map((lang: string, index: number) => {
            const idx = translations.findIndex((item: WebFooterContentTranslation) => item.lang?.code === lang);
            const key = idx < 0 ? index : idx;
            const langCode = getFieldValue(['translations', `${key}`, 'lang']);
            return (
                <Card style={{ width: '100%' }} title={lang.toUpperCase()} key={index}>
                    <Form.Item
                        name={['translations', `${key}`, 'lang']}
                        style={{ display: 'none' }}
                        initialValue={langCode || lang}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={['translations', `${key}`, 'id']} style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    {type === WEB_FOOTER_CONTENT_TYPE_LINK && (
                        <Form.Item rules={[{ required: true }]} name={['translations', `${key}`, 'link']}>
                            <Input placeholder="Лінк" />
                        </Form.Item>
                    )}
                    <Form.Item
                        name={['translations', `${key}`, 'content']}
                        rules={[{ required: true }]}
                        valuePropName={type === WEB_FOOTER_CONTENT_TYPE_HTML ? "setContents" : "value"}>
                        {type === WEB_FOOTER_CONTENT_TYPE_HTML ? (
                            <SunEditor height={EDITOR_HEIGHT} width={EDITOR_WIDTH} setOptions={{ buttonList }} />
                        ) : (
                            <Input placeholder="Контент" />
                        )}
                    </Form.Item>
                </Card>
            );
        })}
    </>
);

export default WebFooterContentDataFields;
