import { Button, Form, Spin, message } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NewsModel } from '../../../models/NewsModel';
import NewsDataFields from './NewsDataFields';
import { createFile } from '../../../redux/api/filesApi';
import { useDeleteFileMutation } from '../../../redux/api/filesApi';

interface NewsFormProps {
    news: NewsModel | null;
    isLoading: boolean;
    onSubmitValues: (data: NewsModel) => any;
}

interface IState {
    isUploading: boolean;
    imageMemo?: string;
    imageHash?: string;
}

const UpdateNewsForm: FC<NewsFormProps> = ({ isLoading, news, onSubmitValues }) => {
    const [form] = useForm<NewsModel>();
    const [state, setState] = useState<IState>({ isUploading: false, imageMemo: undefined, imageHash: '' });
    const file = useWatch('file', form);
    const navigate = useNavigate();
    const { translations } = news || {};
    const [deleteFile, { isLoading: isDeleting, isSuccess }] = useDeleteFileMutation();

    const resetImage = useCallback(() => {
        setState((prevState) => ({ ...prevState, imageMemo: undefined }));
        form.setFieldValue('file', undefined);
    }, [form]);

    const uploadImage = useCallback(async () => {
        let id: string;
        if (form.getFieldValue('file')) {
            setState((prevState) => ({ ...prevState, isUploading: true }));
            const formData = new FormData();
            formData.append('file', form.getFieldValue('file'));
            let result: Array<{ id: string }> = [];
            try {
                result = await createFile(formData);
                id = result?.[0]?.id;
            } catch (e: any) {
                message.error(e.message);
            }
        }
        setState((prevState) => ({ ...prevState, imageHash: id ? id : state.imageMemo, isUploading: false }));
    }, [form, state.imageMemo]);

    const onFinish = () => {
        if (news) {
            const finalNews: NewsModel = {
                date: form.getFieldValue('date'),
                published: form.getFieldValue('published'),
                image: state.imageHash,
                id: news.id,
                translations: Object.values(form.getFieldsValue().translations as any),
            };
            onSubmitValues(finalNews)
                .unwrap()
                .then(() => {
                    if (news?.image && news.image !== state.imageHash) {
                        deleteFile(news.image);
                    } else {
                        navigate('/news', { replace: true });
                    }
                })
                .catch((error: any) => {
                    message.error(error.data.message);
                });
        }
    };

    useEffect(() => {
        if (news) {
            setState((prevState) => ({ ...prevState, imageMemo: news.image }));
            form.setFieldsValue({
                ...news,
                date: news?.date ? moment(news.date) : undefined,
            } as NewsModel);
        }
    }, [news, form]);

    useEffect(() => {
        if (state.imageHash) {
            onFinish();
        }
    }, [state.imageHash]);

    useEffect(() => {
        if (isSuccess) {
            navigate('/news', { replace: true });
        }
    }, [isSuccess]);

    return (
        <Spin spinning={isLoading || isDeleting || state.isUploading}>
            <Form<NewsModel>
                form={form}
                onFinish={uploadImage}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                <NewsDataFields
                    translations={translations}
                    setFieldValue={form.setFieldValue}
                    getFieldValue={form.getFieldValue}
                    resetImage={resetImage}
                    isLoading={state.isUploading}
                    image={state.imageMemo}
                    file={file}
                    updateForm
                />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default UpdateNewsForm;
