const BASE_URL = process.env.REACT_APP_BASE_URL as string;

export function imageURLToAntdModel(image: any) {
  const [type] = image?.filename.split('.').slice(-1);
  return {
    thumbUrl: `${BASE_URL}storage/files/${image?._id}`,
    name: image?.filename,
    uid: String(image?._id),
    type: `image/${type}`,
    size: image?.size || 0,
  };
}
