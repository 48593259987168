import React, { useCallback } from 'react';
import EditWebFooterContentForm from '../../components/forms/content-form/EditWebFooterContentForm';
import FormPageLayout from '../../components/common/FormPageLayout';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useDeleteWebFooterContentMutation,
    useGetWebFooterContentByIdQuery,
    useUpdateWebFooterContentMutation,
} from '../../redux/api/webFooterContentApi';
import { MODE_WEB_FOOTER_CONTENT, ROUTE_CONTENT } from '../../constants/ContentConstants';
import { message } from 'antd';
import { WebFooterContentModel } from '../../models/WebFooterContentModel';
import { useGetWFCCategoryQuery } from '../../redux/api/webFCCategoryApi';
const EditWebFooterContent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading } = useGetWebFooterContentByIdQuery(id as string);
    const { data: categories, isLoading: isCategoryLoading } = useGetWFCCategoryQuery();
    const [updateWebFooterContent, { isLoading: isUpdating }] = useUpdateWebFooterContentMutation();
    const [deleteWebFooterContent, { isLoading: isDeleting }] = useDeleteWebFooterContentMutation();

    const handleSubmit = useCallback((data: WebFooterContentModel) => {
        updateWebFooterContent(data)
            .unwrap()
            .then(() => {
                navigate(`/${ROUTE_CONTENT}/${MODE_WEB_FOOTER_CONTENT}`, { replace: true });
            })
            .catch((error: any) => {
                message.error(error.data.message);
            });
    }, []);

    const handleDelete = useCallback((id: string) => {
        deleteWebFooterContent(id)
            .then(() => {
                navigate(`/${ROUTE_CONTENT}/${MODE_WEB_FOOTER_CONTENT}`, { replace: true });
            })
            .catch((error: any) => {
                message.error(error.data.message);
            });
    }, []);

    return (
        <FormPageLayout backButtonLabel="Футер">
            <EditWebFooterContentForm
                isLoading={isLoading || isCategoryLoading || isUpdating || isDeleting}
                onSubmit={handleSubmit}
                onDelete={handleDelete}
                data={data}
                categories={categories}
            />
        </FormPageLayout>
    );
};

export default EditWebFooterContent;
