import { Card, Layout, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import bigLogo from '../../assets/images/big-logo.svg';
import logo from '../../assets/images/logo.png';
import { defaultFunctions } from '../../constants/DefaultFunctions';
import RouteList, { menuIcons } from '../../constants/enums/RouterList';
import { getRouting } from '../../helpers/getRouting';
import { RouterListModel } from '../../models/RouterListModel';
import { getUser } from '../../redux/features/authSlice';
import { useAppSelector } from '../../redux/hooks';
import Header from './Header';
import Routers from './Routers';

const { Content, Footer, Sider } = Layout;

const initMenuItems = (routings: RouterListModel[]) => {
    return routings
        .sort((a: RouterListModel, b: RouterListModel) => a.ord - b.ord)
        .map(
            (route: RouterListModel) =>
                ({
                    key: route.code,
                    icon: route.icon && menuIcons[route.icon],
                    label: route.name,
                } as ItemType),
        );
};

const MainLayout: FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();

    const handleMenuSelect = ({ key }: any) => {
        const route = RouteList.find((item: RouterListModel) => item.code === key);
        if (route) {
            navigate(route.path as any);
        }
    };

    const user = useAppSelector(getUser);
    const functions = [...(user?.menuFunctions || []), ...defaultFunctions];
    const routings = getRouting(functions);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                {!collapsed ? (
                    <div className="logo">
                        <img src={bigLogo} alt="logo" width={'60%'} />
                    </div>
                ) : (
                    <div className="logo">
                        <img src={logo} alt="logo" width={'32px'} />
                    </div>
                )}
                <Menu
                    theme="dark"
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    items={initMenuItems(routings)}
                    onSelect={handleMenuSelect}
                />
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }} />
                <Content style={{ margin: '20px' }}>
                    <Card style={{ height: '100%' }}>
                        <Routers />
                    </Card>
                </Content>
                <Footer style={{ textAlign: 'center' }}>NIB ©2022</Footer>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
