import React, { FC, useCallback } from 'react';
import WebContentDataFields from './WebContentDataFields';
import { Button, Form, Spin } from 'antd';
import { WebContentModel } from '../../../models/WebContentModel';
import { useForm } from 'antd/lib/form/Form';

interface TextFormProps {
    isLoading: boolean;
    onSubmit: (data: object) => void;
}

const CreateWebContentForm: FC<TextFormProps> = ({ isLoading, onSubmit }) => {
    const [form] = useForm<WebContentModel>();

    const onFinish = useCallback(() => {
        const finalContent: WebContentModel = {
            code: form.getFieldValue('code'),
            translations: Object.values(form.getFieldsValue().translations as any),
        };
        onSubmit(finalContent);
    }, []);

    return (
        <Spin spinning={isLoading}>
            <Form<WebContentModel>
                form={form}
                onFinish={onFinish}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                <WebContentDataFields getFieldValue={form.getFieldValue} />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default CreateWebContentForm;
