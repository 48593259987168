import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FormPageLayout from '../../components/common/FormPageLayout';
import UpdateRequestForm from '../../components/forms/requst-form/UpdateRequestForm';
import { useGetRequestQuery, useUpdateRequestMutation } from '../../redux/api/requestApi';

const EditRequest: FC = () => {
    const { requestId } = useParams();
    const {
        data: request,
        isLoading,
        isFetching,
        refetch,
    } = useGetRequestQuery(requestId ?? skipToken, {
        refetchOnMountOrArgChange: true,
    });

    const [updateRequest, { isLoading: isUpdating }] = useUpdateRequestMutation();
    const [viewMode, setViewMode] = useState<boolean>(true);

    const handleEditMode = (value: boolean) => {
        setViewMode(value);
        if (value) {
            refetch();
        }
    };

    return (
        <FormPageLayout
            backButtonLabel="Запити"
            actions={
                viewMode ? (
                    <Button type="primary" onClick={() => handleEditMode(false)}>
                        <EditOutlined /> Редагувати
                    </Button>
                ) : (
                    <Button onClick={() => handleEditMode(true)}>
                        <CloseOutlined /> Відмінити
                    </Button>
                )
            }>
            <UpdateRequestForm
                request={request || null}
                isLoading={isLoading || isFetching || isUpdating}
                viewMode={viewMode}
                onSubmitValues={updateRequest}
            />
        </FormPageLayout>
    );
};

export default EditRequest;
