import { Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Space, Upload } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { ReactElement } from 'react';
import { imageURLToAntdModel } from '../../../helpers/imageToAntdModel';
import { CommonDictionaryModel } from '../../../models/CommonDictionaryModel';
import { DictionaryAddressModel } from '../../../models/DictionaryAddressModel';

export interface RequestBlockModel {
    key: string;
    title: string;
    fields: ReactElement | Function;
    isRelate?: boolean;
}

interface RequestDictionaries {
    requestTypes?: CommonDictionaryModel[];
    lookingForCategories?: CommonDictionaryModel[];
    lookingForTypes?: CommonDictionaryModel[];
    countries?: CommonDictionaryModel[];
    gender?: CommonDictionaryModel[];
    militaryFormation?: CommonDictionaryModel[];
    militaryRank?: CommonDictionaryModel[];
    physicalCondition?: CommonDictionaryModel[];
    regions?: DictionaryAddressModel[];
    cities?: DictionaryAddressModel[];
    lossCities?: DictionaryAddressModel[];
    identifyFiles?: any[];
    documentsConfirmingFiles?: any[];
    viewMode?: boolean;
    formWatch: (name: NamePath) => any;
}

export const getSelectOptionFromDictionary = (item: CommonDictionaryModel) => (
    <Select.Option key={item.code} value={item.code}>
        {item.translations[0].description}
    </Select.Option>
);

export const getSelectOptionFromAddressDictionary = (item: DictionaryAddressModel) => (
    <Select.Option key={item.id} value={item.id}>
        {item.short_name}
    </Select.Option>
);

const selectFilterOption = (value: string, options: any) => {
    const { children } = options;
    return children.includes(value);
};

export const requestBlocks = ({
    requestTypes = [],
    lookingForCategories = [],
    lookingForTypes = [],
    countries = [],
    gender = [],
    militaryFormation = [],
    militaryRank = [],
    physicalCondition = [],
    regions = [],
    cities = [],
    lossCities = [],
    identifyFiles = [],
    documentsConfirmingFiles = [],
    viewMode,
    formWatch,
}: RequestDictionaries): RequestBlockModel[] => {
    const isDisabled = (custom: boolean) => viewMode || (!viewMode && custom);
    return [
        {
            key: 'block1',
            title: 'Повідомити про',
            fields: (
                <Form.Item name={['type', 'code']}>
                    <Select<CommonDictionaryModel['code']>>{requestTypes?.map(getSelectOptionFromDictionary)}</Select>
                </Form.Item>
            ),
        },
        {
            key: 'block2',
            title: 'До якої категорії відноситься особа, яка розшукується?',
            fields: (
                <Form.Item name={['lookingForCategory', 'code']}>
                    <Select<CommonDictionaryModel['code']>>
                        {lookingForCategories?.map(getSelectOptionFromDictionary)}
                    </Select>
                </Form.Item>
            ),
        },
        {
            key: 'block3',
            title: 'Розшукувана особа це мій(моя)',
            fields: (
                <Form.Item name={['lookingForType', 'code']}>
                    <Select<CommonDictionaryModel['code']>>
                        {lookingForTypes?.map(getSelectOptionFromDictionary)}
                    </Select>
                </Form.Item>
            ),
        },
        {
            key: 'block4',
            title: 'ПІБ особи, що розшукується',
            fields: (
                <>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Прізвище" name={'surname'}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Ім'я" name={'name'}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="По батькові" name={'patronymic'}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            key: 'block5',
            title: 'Дата народження особи, яка розшукується',
            fields: () => {
                const isUnknownDateOfBirth = formWatch('unknownDateOfBirth');
                return (
                    <>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Дата народження" name="dateOfBirth">
                                    <DatePicker disabled={isDisabled(isUnknownDateOfBirth)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item name="unknownDateOfBirth" valuePropName="checked">
                                    <Checkbox>Не знаю точну дату народження</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                );
            },
        },
        {
            key: 'block6',
            title: 'Номер телефону особи',
            fields: () => {
                const isUnknownCellPhone = formWatch('unknownCellPhone');
                return (
                    <>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Номер" name="cellPhone">
                                    <Input disabled={isDisabled(isUnknownCellPhone)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="" name="unknownCellPhone" valuePropName="checked">
                                    <Checkbox>Не знаю номер телефону</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                );
            },
        },
        {
            key: 'block7',
            title: 'Громадянство',
            fields: () => {
                const isUnknownCitizenship = formWatch('unknownCitizenship');
                return (
                    <>
                        <Row>
                            <Col span={24}>
                                <Form.Item name={['citizenship', 'code']}>
                                    <Select<CommonDictionaryModel['code']>
                                        showSearch
                                        filterOption={selectFilterOption}
                                        disabled={isDisabled(isUnknownCitizenship)}>
                                        {countries?.map(getSelectOptionFromDictionary)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="" name="unknownCitizenship" valuePropName="checked">
                                    <Checkbox>Не знаю громадянства</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                );
            },
        },
        {
            key: 'block8',
            title: 'Місце реєстрації',
            fields: () => {
                const isUkrainian = formWatch(['citizenship', 'code']) === 'UA';
                const isUnknownCitizenship = formWatch('unknownCitizenship');
                const isUnknownAddress = formWatch('unknownAddress');
                return (
                    <>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="" name="unknownAddress" valuePropName="checked">
                                    <Checkbox>не знаю адресу</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        {isUkrainian && !isUnknownCitizenship ? (
                            <>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="Область" name={['region', 'id']}>
                                            <Select<DictionaryAddressModel['id']>
                                                showSearch
                                                disabled={isDisabled(isUnknownAddress)}
                                                filterOption={selectFilterOption}>
                                                {regions?.map(getSelectOptionFromAddressDictionary)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="Населений пункт " name={['city', 'id']}>
                                            <Select<DictionaryAddressModel['id']>
                                                showSearch
                                                disabled={isDisabled(isUnknownAddress)}
                                                filterOption={selectFilterOption}>
                                                {cities?.map(getSelectOptionFromAddressDictionary)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="Вулиця" name={'street'}>
                                            <Input disabled={isDisabled(isUnknownAddress)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="Будинок" name={'building'}>
                                            <Input disabled={isDisabled(isUnknownAddress)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="Квартира" name={'apartment'}>
                                            <Input disabled={isDisabled(isUnknownAddress)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item label="Адреса" name={'address'}>
                                            <Input disabled={isDisabled(isUnknownAddress)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </>
                );
            },
        },
        {
            key: 'block9',
            title: 'Стать',
            fields: (
                <>
                    {gender.length > 0 && (
                        <Row>
                            <Col span={24}>
                                <Form.Item name={['gender', 'code']}>
                                    <Radio.Group>
                                        <Space direction="vertical">
                                            {gender.map((item: CommonDictionaryModel) => (
                                                <Radio key={item.code} value={item.code}>
                                                    {item.translations[0].description}
                                                </Radio>
                                            ))}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </>
            ),
        },
        {
            key: 'block10',
            title: 'Військовий',
            fields: (
                <>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Військове формування" name={['militaryFormation', 'code']}>
                                <Select<CommonDictionaryModel['code']> showSearch filterOption={selectFilterOption}>
                                    {militaryFormation?.map(getSelectOptionFromDictionary)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Військова частина" name={'militaryBase'}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Військове звання" name={['militaryRank', 'code']}>
                                <Select<CommonDictionaryModel['code']> showSearch filterOption={selectFilterOption}>
                                    {militaryRank?.map(getSelectOptionFromDictionary)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Посада" name={'specialization'}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Позивний" name={'militaryNickname'}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Армійський номер" name={'militaryNumber'}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            key: 'block11',
            title: 'Примусово мобілізовані на ТОТ',
            fields: (
                <>
                    <Row>
                        <Col span={24}>
                            Я точно знаю військову частину, до якої примусово мобілізували особу (номер,
                            місцезнаходження в/ч)
                            <Form.Item name={'knownMilitaryPart'}>
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            Я знаю звання, посаду особи, отримані при примусовій мобілізації
                            <Form.Item name={'knownRank'}>
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            key: 'block12',
            title: 'Дата загибелі/депортації/полону/зникнення/примусової мобілізації',
            fields: () => {
                const isUnknownDateOfLossCommunication = formWatch('unknownDateOfLossCommunication');
                return (
                    <>
                        <Row>
                            <Col span={24}>
                                <Form.Item name="dateOfLossCommunication">
                                    <DatePicker disabled={isDisabled(isUnknownDateOfLossCommunication)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item name="unknownDateOfLossCommunication" valuePropName="checked">
                                    <Checkbox>Не знаю точну дату втрати зв'язку</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                );
            },
        },
        {
            key: 'block13',
            title: 'Місце загибелі/депортації/полону/зникнення/примусової мобілізації',
            fields: (
                <>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Область" name={['lossRegion', 'id']}>
                                <Select<DictionaryAddressModel['id']> showSearch filterOption={selectFilterOption}>
                                    {regions?.map(getSelectOptionFromAddressDictionary)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Населений пункт " name={['lossCity', 'id']}>
                                <Select<DictionaryAddressModel['id']> showSearch filterOption={selectFilterOption}>
                                    {lossCities?.map(getSelectOptionFromAddressDictionary)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            Детальніше про місце та обставини загибелі/депортації/полону/зникнення/примусової
                            мобілізації
                            <Form.Item name={'lossPlaceDetails'}>
                                <Input.TextArea rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            key: 'block14',
            title: 'Чи відоме Вам поточне місце знаходження особи, яка розшукується?',
            fields: () => {
                const isUnknownCurrentLocation = formWatch('unknownCurrentLocation');
                return (
                    <>
                        <Row>
                            <Col span={24}>
                                <Form.Item name="unknownCurrentLocation">
                                    <Radio.Group>
                                        <Space direction="vertical">
                                            <Radio value={true}>Так</Radio>
                                            <Radio value={false}>Ні</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        {isUnknownCurrentLocation && (
                            <Row>
                                <Col span={24}>
                                    Поточне місце знаходження особи, яка розшукується
                                    <Form.Item name={'currentLocation'}>
                                        <Input.TextArea rows={2} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                    </>
                );
            },
        },
        {
            key: 'block15',
            title: 'Особливі прикмети особи, яка розшукується',
            fields: (
                <>
                    <Row>
                        <Col span={24}>
                            (тату, родимки, шрами, специфічні прикраси)
                            <Form.Item name={'specialFeatures'}>
                                <Input.TextArea rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            key: 'block16',
            title: 'Фізичний стан особи',
            fields: (
                <>
                    {physicalCondition.length > 0 && (
                        <Row>
                            <Col span={24}>
                                <Form.Item name={['physicalCondition', 'code']}>
                                    <Radio.Group>
                                        <Space direction="vertical">
                                            {physicalCondition.map((item: CommonDictionaryModel) => (
                                                <Radio key={item.code} value={item.code}>
                                                    {item.translations[0].description}
                                                </Radio>
                                            ))}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col span={24}>
                            Додаткова інформація стосовно хвороби або поранення
                            <Form.Item name={'physicalConditionDetails'}>
                                <Input.TextArea rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            key: 'block17',
            title: 'Я хочу додати фото особи, яку я розшукую, щоб допомогти ідентифікувати її',
            fields: () => {
                const files = identifyFiles.map(imageURLToAntdModel);
                return (
                    <Upload
                        listType="picture"
                        accept=".png, .jpg, .jpeg"
                        beforeUpload={() => false}
                        fileList={files}
                        disabled={true}
                        customRequest={() => {}}
                    />
                );
            },
        },
        {
            key: 'block18',
            title: 'Я хочу додати фото, відео або інші документи, що підтверджують обставини зникнення',
            fields: () => {
                const files = documentsConfirmingFiles.map(imageURLToAntdModel);
                return (
                    <Upload
                        listType="picture"
                        accept=".png, .jpg, .jpeg"
                        beforeUpload={() => false}
                        fileList={files}
                        disabled={true}
                        customRequest={() => {}}
                    />
                );
            },
        },
        {
            key: 'block19',
            title: 'Я хочу додати посилання на джерела що підтверджують обставини зникнення',
            fields: (
                <>
                    <Row>
                        <Col span={24}>
                            <Form.Item name={'sourceConfirmingFiles'}>
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            key: 'block20',
            title: 'Контакти родичів',
            fields: (
                <>
                    <Row>
                        <Col span={24}>
                            прізвище, ім'я, по батькові, ким приходиться, номер телефону
                            <Form.Item name={'relativesContacts'}>
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            ),
        },
    ];
};
