import { Form, Input, Select } from 'antd';
import { QueryOptions } from 'odata-query';
import { FC, useMemo } from 'react';
import { MenuFunctionModel } from '../../../models/MenuFunctionModel';
import { useGetMenuFunctionsQuery } from '../../../redux/api/usersApi';

interface RoleDataFieldsProps {
    disableName?: boolean;
}

const UserDataFields: FC<RoleDataFieldsProps> = ({ disableName }) => {
    const menuFuncFilter = {
        isActive: true,
    } as MenuFunctionModel;
    const menuFuncQuery = useMemo<Partial<QueryOptions<MenuFunctionModel>>>(() => ({
        filter: menuFuncFilter,
        orderBy: 'name asc',
    }), [menuFuncFilter]);
    const { data: menuFunctions, isLoading: menuIsLoading, isFetching: menuIsFetching } = useGetMenuFunctionsQuery(menuFuncQuery);

    return (
        <>
            <Form.Item name="name" rules={[{ required: true }]}>
                <Input placeholder="Назва" disabled={disableName} />
            </Form.Item>
            <Form.Item name="description">
                <Input placeholder="Опис" />
            </Form.Item>
            <Form.Item name="menuFunctions">
                <Select
                    mode="multiple"
                    placeholder="Оберіть функції"
                    style={{ width: '100%' }}
                    fieldNames={{ value: 'id', label: 'name' }}
                    options={menuFunctions as any}
                    loading={menuIsLoading || menuIsFetching}
                />
            </Form.Item>
        </>
    );
};

export default UserDataFields;
