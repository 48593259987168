import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import RouteList from '../../constants/enums/RouterList';
import { RouterListModel } from '../../models/RouterListModel';
import Dashboard from '../../pages/Dashboard';

const Routers: FC = () => {
    const Default = <Dashboard />;
    return (
        <Routes>
            {RouteList.map((route: RouterListModel) => (
                <Route key={route.code} path={route.path || ''} element={<route.component />}></Route>
            ))}
            <Route path="*" element={Default} />
        </Routes>
    );
};

export default Routers;
