import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import FormPageLayout from '../../components/common/FormPageLayout';
import CreateRoleForm from '../../components/forms/role-form/CreateRoleForm';
import { MenuFunctionModel } from '../../models/MenuFunctionModel';
import { RoleModel } from '../../models/RoleModel';
import { useCreateRoleMutation } from '../../redux/api/rolesApi';

const CreateRole: FC = () => {
    const [createRole, { isLoading }] = useCreateRoleMutation();

    const navigate = useNavigate();

    const handleSubmit = async (data: RoleModel) => {
        const request = await createRole(data).unwrap();
        if (request.name) {
            navigate(`/roles/${request.name}`);
        }
    }

    return (
        <FormPageLayout backButtonLabel="Ролі">
            <CreateRoleForm isLoading={isLoading} onSubmitValues={handleSubmit} />
        </FormPageLayout>
    );
};

export default CreateRole;
