import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import FormPageLayout from '../../components/common/FormPageLayout';
import CreateNewsForm from '../../components/forms/news-form/CreateNewsForm';
import { NewsModel } from '../../models/NewsModel';
import { useCreateNewsMutation } from '../../redux/api/newsApi';
import { message } from 'antd';
const CreateNews: FC = () => {
    const [createNews, { isLoading }] = useCreateNewsMutation();

    const navigate = useNavigate();

    const handleSubmit = async (data: NewsModel) => {
        try{
            const request = await createNews(data).unwrap();
            if (request.id) {
                navigate(`/news/${request.id}`);
            }
        } catch(e: any){
            message.error(e.data.message);
        }

    };

    return (
        <FormPageLayout backButtonLabel="Новини">
            <CreateNewsForm isLoading={isLoading} onSubmitValues={handleSubmit} />
        </FormPageLayout>
    );
};

export default CreateNews;
