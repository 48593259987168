import { Button, Form, message, Spin } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { FC, useCallback, useState } from 'react';
import NewsDataFields from './NewsDataFields';
import { NewsModel } from '../../../models/NewsModel';
import { createFile } from '../../../redux/api/filesApi';

interface NewsFormProps {
    isLoading: boolean;
    onSubmitValues: (data: NewsModel) => any;
}

const CreateNewsForm: FC<NewsFormProps> = ({ isLoading, onSubmitValues }) => {
    const [form] = useForm<NewsModel>();
    const [processing, setProcessing] = useState(false);
    const file = useWatch('file', form);

    const resetImage = useCallback(() => {
        form.setFieldValue('file', undefined);
    }, [form]);

    const onFinish = useCallback(
        (image: string) => {
            const {file, translations, ...values} = form.getFieldsValue();
            const finalNews: NewsModel = {
                ...values,
                image,
                translations: Object.values(translations as any),
            };
            onSubmitValues(finalNews);
        },
        [form, onSubmitValues],
    );

    const uploadImage = useCallback(async () => {
        let result: Array<{ id: string }> = [];
        if (file) {
            setProcessing(true);
            const formData = new FormData();
            formData.append('file', file);
            try {
                result = await createFile(formData);
                if (result.length) {
                    onFinish(result[0].id);
                }
            } catch (e: any) {
                message.error(e.message);
            }
        }
        setProcessing(false);
    }, [file, onFinish]);

    return (
        <Spin spinning={isLoading}>
            <Form<NewsModel>
                form={form}
                onFinish={uploadImage}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                <NewsDataFields
                    setFieldValue={form.setFieldValue}
                    getFieldValue={form.getFieldValue}
                    resetImage={resetImage}
                    isLoading={processing}
                    file={file}
                />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default CreateNewsForm;
