import React, { FC, useCallback, useEffect } from 'react';
import WebContentDataFields from './WebContentDataFields';
import { Button, Col, Form, Row, Spin } from 'antd';
import { WebContentModel } from '../../../models/WebContentModel';
import { useForm } from 'antd/lib/form/Form';

interface TextFormProps {
    isLoading: boolean;
    onSubmit: (data: WebContentModel) => void;
    onDelete: (id: string) => void;
    data: WebContentModel | null;
}

const EditWebContentForm: FC<TextFormProps> = ({ isLoading, onSubmit, onDelete, data }) => {
    const [form] = useForm<WebContentModel>();
    const onFinish = useCallback(() => {
        const finalContent: WebContentModel = {
            code: form.getFieldValue('code'),
            translations: Object.values(form.getFieldsValue().translations as any),
        };
        onSubmit(finalContent);
    }, []);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                ...data,
            } as WebContentModel);
        }
    }, [data, form]);

    return (
        <Spin spinning={isLoading}>
            <Form<WebContentModel>
                form={form}
                onFinish={onFinish}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                <Col span={24}>
                    <Row justify="end">
                        <Button onClick={() => onDelete(form.getFieldValue('code'))} type="primary">
                            Видалити контент
                        </Button>
                    </Row>
                </Col>
                <WebContentDataFields
                    translations={data?.translations || []}
                    getFieldValue={form.getFieldValue}
                    updateForm
                />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default EditWebContentForm;
