import { Card, DatePicker, Form, Input, Image, Upload, message, Button, Checkbox } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { NamePath } from 'antd/lib/form/interface';
import { FC, useCallback } from 'react';
import { Langs } from '../../../constants/enums/Langs';
import { NewsTranslationModel } from '../../../models/NewsModel';
import SunEditor from 'suneditor-react';

const BASE_URL = process.env.REACT_APP_BASE_URL as string;
const buttonList = [
    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'fontColor'],
    ['table', 'link', 'video'],
    '/',
    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'list'],
    ['fullScreen', 'showBlocks', 'codeView', 'preview'],
];

const EDITOR_HEIGHT = '600';
const EDITOR_WIDTH = '740';
const MAX_FILE_SIZE = 50;
interface NewsDataFieldsProps {
    translations?: NewsTranslationModel[];
    setFieldValue: (name: NamePath, value: any) => void;
    getFieldValue: (name: NamePath) => any;
    resetImage: () => void;
    image?: string;
    isLoading: boolean;
    file?: File;
    updateForm?: boolean;
}

const style = { marginTop: 8 };
const imageStyle = { marginBottom: 24 };
const UserDataFields: FC<NewsDataFieldsProps> = ({
    translations = [],
    setFieldValue,
    getFieldValue,
    resetImage,
    image,
    isLoading,
    file,
    updateForm,
}) => {
    const langs = Object.keys(Langs);

    const handleChangeImage = useCallback(({ file }: UploadChangeParam) => {
        if (file.size) {
            const isLt2M = file.size / 1024 / 1024 < MAX_FILE_SIZE;
            if (!isLt2M) {
                message.error(`Максимальний розмір файлу ${MAX_FILE_SIZE} мб`);
            }
            setFieldValue('file', isLt2M ? file.originFileObj : undefined);
        }
    }, []);

    const getContent = useCallback(
        (idx: number) => {
            if (translations.length > 0) {
                return translations[idx].content;
            }
            return '';
        },
        [translations],
    );

    return (
        <>
            {(image || file) && (
                <div style={imageStyle}>
                    <div style={imageStyle}>
                        <Image
                            width={'50%'}
                            src={image ? `${BASE_URL}storage/files/${image}` : window.URL.createObjectURL(file as File)}
                        />
                    </div>
                    <Button onClick={resetImage}>Видалити зображення</Button>
                </div>
            )}
            <Form.Item hidden={!!(image || file)} name="file" rules={[{ required: !(image || file) }]}>
                <Upload.Dragger
                    accept=".png, .jpg, .jpeg"
                    listType="picture-card"
                    showUploadList={false}
                    onChange={handleChangeImage}
                    customRequest={() => {}}>
                    <div>
                        {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
                        <div style={style}>Оберіть зображення</div>
                    </div>
                </Upload.Dragger>
            </Form.Item>
            <Form.Item name="date" rules={[{ required: true }]}>
                <DatePicker />
            </Form.Item>
            <Form.Item name="published" valuePropName="checked">
                <Checkbox>Опублікувати</Checkbox>
            </Form.Item>
            {langs.map((lang: string, index: number) => {
                const idx = translations.findIndex((item: NewsTranslationModel) => item.lang?.code === lang);
                const key = idx < 0 ? index : idx;
                const content = getContent(key);
                const langCode = getFieldValue(['translations', `${key}`, 'lang', 'code']);
                return (
                    <Card style={{ width: '100%' }} title={lang.toUpperCase()} key={lang}>
                        {/* XXX: invisible field */}
                        <Form.Item
                            name={['translations', `${key}`, 'id']}
                            style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['translations', `${key}`, 'linkName']}
                            style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['translations', `${key}`, 'lang', 'code']}
                            style={{ display: 'none' }}
                            initialValue={langCode || lang}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={['translations', `${key}`, 'title']}>
                            <Input placeholder="Заголовок" />
                        </Form.Item>
                        <Form.Item name={['translations', `${key}`, 'content']}>
                            {updateForm ? (
                                content && (
                                    <SunEditor
                                        height={EDITOR_HEIGHT}
                                        width={EDITOR_WIDTH}
                                        setOptions={{ buttonList }}
                                        setContents={content}
                                    />
                                )
                            ) : (
                                <SunEditor
                                    height={EDITOR_HEIGHT}
                                    width={EDITOR_WIDTH}
                                    setOptions={{ buttonList }}
                                    setContents={content}
                                />
                            )}
                        </Form.Item>
                    </Card>
                );
            })}
        </>
    );
};

export default UserDataFields;
