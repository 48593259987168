import React, { FC, useCallback } from 'react';
import { Card, Form, Input } from 'antd';
import SunEditor from 'suneditor-react';
import { Langs } from '../../../constants/enums/Langs';
import { WebContentTranslation } from '../../../models/WebContentModel';
import { NamePath } from 'antd/lib/form/interface';

const EDITOR_HEIGHT = '600';
const EDITOR_WIDTH = '740';
const buttonList = [
    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'fontColor'],
    ['table', 'link', 'video'],
    '/',
    ['fontColor', 'hiliteColor', 'outdent', 'indent', 'align', 'list'],
    ['fullScreen', 'showBlocks', 'codeView', 'preview'],
];
const inputWidth = { width: 220 };
const langs = Object.keys(Langs);
interface WebContentDataFields {
    translations?: WebContentTranslation[];
    getFieldValue: (name: NamePath) => any;
    updateForm?: boolean;
}

const TextDataFields: FC<WebContentDataFields> = ({ translations = [], getFieldValue, updateForm }) => {
    const getContent = useCallback(
        (idx: number) => {
            if (translations.length > 0) {
                return translations[idx].content;
            }
            return '';
        },
        [translations],
    );

    return (
        <>
            <Form.Item name={'code'} rules={[{ required: true }]}>
                <Input placeholder="code" disabled={updateForm} style={inputWidth}/>
            </Form.Item>
            {langs.map((lang: string, index: number) => {
                const idx = translations.findIndex((item: WebContentTranslation) => item.lang?.code === lang);
                const key = idx < 0 ? index : idx;
                const content = getContent(key);
                const langCode = getFieldValue(['translations', `${key}`, 'lang']);
                return (
                    <Card style={{ width: '100%' }} title={lang.toUpperCase()} key={index}>
                        <Form.Item
                            name={['translations', `${key}`, 'lang']}
                            style={{ display: 'none' }}
                            initialValue={langCode || lang}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['translations', `${key}`, 'id']}
                            style={{ display: 'none' }}
                            hidden={!updateForm}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={['translations', `${key}`, 'title']} rules={[{ required: true }]}>
                            <Input placeholder="Заголовок" />
                        </Form.Item>
                        <Form.Item name={['translations', `${key}`, 'content']} rules={[{required: true}]}>
                            {updateForm ? content && <SunEditor
                                height={EDITOR_HEIGHT}
                                width={EDITOR_WIDTH}
                                setContents={content}
                                setOptions={{buttonList}}
                            /> : <SunEditor
                                height={EDITOR_HEIGHT}
                                width={EDITOR_WIDTH}
                                setOptions={{buttonList}}
                            /> }
                        </Form.Item>
                        <Form.Item name={['translations', `${key}`, 'footer']} rules={[{ required: true }]}>
                            <Input placeholder="Футер" />
                        </Form.Item>
                    </Card>
                );
            })}
        </>
    );
};
export default TextDataFields;
