import React from 'react';
import CreateWebContentForm from '../../components/forms/content-form/CreateWebContentForm';
import FormPageLayout from '../../components/common/FormPageLayout';
import { useCreateTextContentMutation } from '../../redux/api/webContentApi';
import { WebContentModel } from '../../models/WebContentModel';
import { message } from 'antd';
import { MODE_WEB_CONTENT, ROUTE_CONTENT } from '../../constants/ContentConstants';
import { useNavigate } from 'react-router-dom';
const CreateWebContent = () => {
    const [createContent, { isLoading }] = useCreateTextContentMutation();
    const navigate = useNavigate();
    const handleSubmit = async (data: WebContentModel) => {
        try {
            const request = await createContent(data).unwrap();
            if (request.code) {
                navigate(`/${ROUTE_CONTENT}/${MODE_WEB_CONTENT}/${request.code}`);
            }
        } catch (e: any) {
            message.error(e.data.message);
        }
    };

    return (
        <FormPageLayout backButtonLabel="Контент">
            <CreateWebContentForm isLoading={isLoading} onSubmit={handleSubmit} />
        </FormPageLayout>
    );
};

export default CreateWebContent;
