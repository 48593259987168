import React, { useCallback } from 'react';
import EditWebContentForm from '../../components/forms/content-form/EditWebContentForm';
import FormPageLayout from '../../components/common/FormPageLayout';
import {
    useGetTextContentByIdQuery,
    useUpdateTextContentMutation,
    useDeleteContentMutation,
} from '../../redux/api/webContentApi';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';
import { WebContentModel } from '../../models/WebContentModel';
import { ROUTE_CONTENT, MODE_WEB_CONTENT } from '../../constants/ContentConstants';

const EditWebContent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading } = useGetTextContentByIdQuery(id as string);
    const [updateTextContent, { isLoading: isUpdating }] = useUpdateTextContentMutation();
    const [deleteTextContent, { isLoading: isDeleting }] = useDeleteContentMutation();

    const handleSubmit = useCallback((data: WebContentModel) => {
        updateTextContent(data)
            .unwrap()
            .then(() => {
                navigate(`/${ROUTE_CONTENT}/${MODE_WEB_CONTENT}`, { replace: true });
            })
            .catch((error: any) => {
                message.error(error.data.message);
            });
    }, []);
    const handleDelete = useCallback((id: string) => {
        deleteTextContent(id)
            .then(() => {
                navigate(`/${ROUTE_CONTENT}/${MODE_WEB_CONTENT}`, { replace: true });
            })
            .catch((error: any) => {
                message.error(error.data.message);
            });
    }, []);

    return (
        <FormPageLayout backButtonLabel="Контент">
            <EditWebContentForm
                isLoading={isLoading || isUpdating || isDeleting}
                onSubmit={handleSubmit}
                onDelete={handleDelete}
                data={data}
            />
        </FormPageLayout>
    );
};

export default EditWebContent;
