import { createApi } from '@reduxjs/toolkit/query/react';
import customBaseQuery from './common/customBaseQuery';
import {WebContentModel} from "../../models/WebContentModel";

export const webContentApi = createApi({
    reducerPath: 'webContentApi',
    baseQuery: customBaseQuery,
    tagTypes: ['WebContent'],
    endpoints: (builder) => ({
        getTextContent: builder.query<WebContentModel[], void>({
            query() {
                return {
                    url: `business/dictionary/web_contents`,
                    method: 'GET',
                };
            },
            providesTags: ['WebContent'],

        }),
        getTextContentById: builder.query<any, string | number>({
            query(id) {
                return {
                    url: `business/dictionary/web_contents/${id}`,
                    method: 'GET',
                };
            },
            providesTags: ['WebContent'],
            transformResponse: (response: WebContentModel) => {
                const translations = response.translations?.map(item => {
                                const lang = item?.lang?.code;
                                return {...item, lang}
                            })
                return {...response, translations};
            },
        }),
        updateTextContent: builder.mutation<WebContentModel, WebContentModel>({
            query(data) {
                const {code, ...body} = data;
                return {
                    url: `business/dictionary/web_contents/${data.code}`,
                    method: 'PUT',
                    body: body
                };
            },
            invalidatesTags: ['WebContent'],

        }),
        createTextContent: builder.mutation<WebContentModel, WebContentModel>({
            query(data) {
                return {
                    url: `business/dictionary/web_contents`,
                    method: 'POST',
                    body: data
                };
            },
            invalidatesTags: ['WebContent'],
        }),
        deleteContent: builder.mutation<WebContentModel, string | number>({
            query(id) {
                return {
                    url: `business/dictionary/web_contents/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['WebContent'],
        }),
    }),
});

export const {
    useGetTextContentQuery,
    useGetTextContentByIdQuery,
    useUpdateTextContentMutation,
    useCreateTextContentMutation,
    useDeleteContentMutation,
} = webContentApi;