import { createApi } from '@reduxjs/toolkit/query/react';
import { CreateUpdateRequestModel, RequestModel } from '../../models/RequestModel';
import buildQuery, { QueryOptions } from 'odata-query';
import customBaseQuery from './common/customBaseQuery';

type GetRequestsWithCountResponse = {
    count: number;
    items: RequestModel[];
};

export type GetRequestsResponse = RequestModel[] | GetRequestsWithCountResponse;

export type RequestListResponce = {
    count: number;
    items: RequestModel[];
};

const isResponseWithCount = (response: GetRequestsResponse): response is GetRequestsWithCountResponse => {
    return !!(response as GetRequestsWithCountResponse).items;
};

export const requestApi = createApi({
    reducerPath: 'requestApi',
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getRequest: builder.query<RequestModel, string | number>({
            query(requestId) {
                return {
                    url: `business/app/request/${requestId}`,
                    method: 'GET',
                };
            },
        }),
        getRequests: builder.query<GetRequestsWithCountResponse, Partial<QueryOptions<RequestModel>>>({
            query(queryData) {
                const queryString = buildQuery(queryData);
                return {
                    url: queryString ? `business/app/request${queryString}` : `business/app/request`,
                    method: 'GET',
                };
            },
            transformResponse: (response: GetRequestsResponse) => {
                return isResponseWithCount(response)
                    ? response
                    : {
                          items: response,
                          count: response.length,
                      };
            },
        }),
        updateRequest: builder.mutation<RequestModel, CreateUpdateRequestModel>({
            query(data) {
                return {
                    url: `business/app/request/${data.id}`,
                    method: 'PUT',
                    body: data,
                };
            },
        }),
        createRequest: builder.mutation<RequestModel, CreateUpdateRequestModel>({
            query(data) {
                return {
                    url: `business/app/request`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        downloadRequest: builder.mutation({
            queryFn: async (options, api, extraOptions, baseQuery) => {
                const result: any = await baseQuery({
                    url: `business/request/download`,
                    responseHandler: (response) => response.blob(),
                });
                var hiddenElement = document.createElement('a');
                var url = window.URL || window.webkitURL;
                var blobXlsx = url.createObjectURL(result.data);
                hiddenElement.href = blobXlsx;
                hiddenElement.target = '_blank';
                hiddenElement.download = `request-${Date.now()}-report.csv`;
                hiddenElement.click();
                return { data: null };
            },
        }),
    }),
});

export const {
    useGetRequestsQuery,
    useGetRequestQuery,
    useUpdateRequestMutation,
    useCreateRequestMutation,
    useDownloadRequestMutation,
} = requestApi;
