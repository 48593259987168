import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { FC } from "react";
import { RequestFiltersFields } from "../../../models/RequestModel";
import { TerritoryUnitModel } from "../../../models/TerritoryUnitModel";
import { useGetRequestStatusesQuery } from "../../../redux/api/dictionariesApi";

interface RequestFiltersProps {
    setFilters: (filters: RequestFiltersFields) => void;
}
const RequestFilters: FC<RequestFiltersProps> = ({ setFilters }) => {
    const [form] = useForm<RequestFiltersFields>();

    const { data: requestStatuses } = useGetRequestStatusesQuery();

    const onFinish = (values: RequestFiltersFields) => {
        setFilters(values);
    }

    const handleReset = () => {
        form.resetFields();
    }

    return (
        <Form<RequestFiltersFields>
            form={form}
            onFinish={onFinish}
            layout="inline"
            className="page-filters"
        >
            <Row justify="space-between" style={{ width: '100%' }}>
                <Col>
                    <Row className="filters">
                        <Form.Item name="status">
                            <Select
                                options={requestStatuses?.map((status) => ({ label: status.translations[0].description, value: status.code }))}
                                allowClear
                                placeholder="Статус"
                                mode="multiple"
                            />
                        </Form.Item>
                        <Form.Item name="phone">
                            <Input placeholder="Телефон" />
                        </Form.Item>
                        <Form.Item name="missing">
                            <Input placeholder="ПІБ розшукованого" />
                        </Form.Item>
                        <Form.Item name="applicant">
                            <Input placeholder="ПІБ заявника" />
                        </Form.Item>
                    </Row>
                </Col>
                <Col className="controls">
                    <Row justify="end" align="middle">
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Знайти</Button>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="button" onClick={handleReset}>Очистити</Button>
                        </Form.Item>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};

export default RequestFilters;