import { Button, Popconfirm } from 'antd';
import { TableActionColumnModel } from '../models/TableActionColumnModel';

const getTableActionColumn = ({ options, rowKey = 'id', onDelete = () => {} }: TableActionColumnModel) => {
    const { delete: isDelete = false } = options || {};

    return {
        title: 'Дії',
        render: (_: any, row: any) => (
            <div>
                {isDelete && (
                    <Popconfirm
                        title="Ви впевнені, що хочете видалити?"
                        onConfirm={() => onDelete(row[rowKey])}
                        okText="Так"
                        cancelText="Ні">
                        <Button type="link">
                            Видалити
                        </Button>
                    </Popconfirm>
                )}
            </div>
        ),
    };
};

export default getTableActionColumn;
