import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import FormPageLayout from '../../components/common/FormPageLayout';
import UpdateNewsForm from '../../components/forms/news-form/UpdateNewsForm';
import { useGetOneNewsQuery, useUpdateNewsMutation } from '../../redux/api/newsApi';

const EditNews: FC = () => {
    const { id } = useParams();
    const { data: news, isLoading, isFetching } = useGetOneNewsQuery(id ?? skipToken);

    const [updateNews, { isLoading: isUpdating }] = useUpdateNewsMutation();

    return (
        <FormPageLayout backButtonLabel="Новини">
            <UpdateNewsForm
                news={news || null}
                isLoading={isLoading || isFetching || isUpdating}
                onSubmitValues={updateNews}
            />
        </FormPageLayout>
    );
};

export default EditNews;
