import { Card } from 'antd';
import { FC, ReactElement } from 'react';

interface RequestBlockProps {
    title: string;
    children: ReactElement;
}

const RequestBlock: FC<RequestBlockProps> = ({ title, children }) => {
    return (
        <Card title={title}>
            {children}
        </Card>
    );
};

export default RequestBlock;
