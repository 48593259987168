import React from 'react';
import { useGetTextContentQuery } from '../../redux/api/webContentApi';
import { Button, Col, Row, Table } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { ColumnsType } from 'antd/lib/table';
import { WebContentModel } from '../../models/WebContentModel';
import { ACTION_NEW, MODE_WEB_CONTENT, ROUTE_CONTENT } from '../../constants/ContentConstants';

const columns: ColumnsType<WebContentModel> = [
    {
        title: 'Тип',
        dataIndex: 'code',
        key: 'code',
    },
    {
        title: 'Заголовок',
        dataIndex: 'translations',
        key: 'title',
        render: (value: any, record) => (
            <Link to={`/${ROUTE_CONTENT}/${MODE_WEB_CONTENT}/${record.code}`}>{value?.[0]?.title ?? ''}</Link>
        ),
    },
    {
        title: 'Дата публікації',
        dataIndex: 'createdDate',
        key: 'createdDate',
        render: (value: string) => moment(value).format('DD.MM.YYYY'),
    },
];

const TabWebContent = () => {
    const { data, isLoading, isFetching } = useGetTextContentQuery();
    const collection = data ?? [];

    return (
        <Row>
            <Col span={24}>
                <Row justify="end">
                    <Link to={`/${ROUTE_CONTENT}/${MODE_WEB_CONTENT}/${ACTION_NEW}`}>
                        <Button type="primary">Додати</Button>
                    </Link>
                </Row>
            </Col>
            <Table<any>
                className="main-table"
                rowKey="code"
                loading={isLoading || isFetching}
                columns={columns}
                dataSource={collection}
            />
        </Row>
    );
};

export default TabWebContent;
