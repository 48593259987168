import { RouterListModel } from '../../models/RouterListModel';
import Users from '../../pages/user/Users';
import Register from '../../pages/request/Request';
import Dashboard from '../../pages/Dashboard';
import {
    DashboardOutlined,
    FileSearchOutlined,
    ReadOutlined,
    UsergroupAddOutlined,
    FolderOpenOutlined,
    UserOutlined,
    FileAddOutlined,
} from '@ant-design/icons';
import EditRequest from '../../pages/request/EditRequest';
// import CreateRequest from '../../pages/request/CreateRequest';
import EditUser from '../../pages/user/EditUser';
import CreateUser from '../../pages/user/CreateUser';
import Roles from '../../pages/roles/Roles';
import News from '../../pages/news/News';
import EditRole from '../../pages/roles/EditRole';
import CreateRole from '../../pages/roles/CreateRole';
import EditNews from '../../pages/news/EditNews';
import CreateNews from '../../pages/news/CreateNews';
import Content from '../../pages/content/Content';
import CreateWebFooterContent from '../../pages/content/CreateWebFooterContent';
import CreateWebContent from '../../pages/content/CreateWebContent';
import EditWebContent from '../../pages/content/EditWebContent';
import EditWebFooterContent from '../../pages/content/EditWebFooterContent';
import {ROUTE_CONTENT, MODE_WEB_CONTENT, MODE_WEB_FOOTER_CONTENT, ACTION_NEW} from '../ContentConstants';
import CreateRequest from '../../pages/request/CreateRequest';
export const menuIcons: any = {
    requests: <FileSearchOutlined />,
    request: <FileAddOutlined />,
    users: <UserOutlined />,
    roles: <UsergroupAddOutlined />,
    news: <ReadOutlined />,
    content: <FolderOpenOutlined />,
    dashboard: <DashboardOutlined />,
};

const RouteList: RouterListModel[] = [
    {
        path: '/requests',
        code: 'requests',
        component: Register,
        icon: 'requests',
    },
    {
        path: '/requests/:requestId',
        code: 'edit_requests',
        component: EditRequest,
    },
    {
        path: '/request',
        code: 'request',
        component: CreateRequest,
        icon: 'request',
    },
    {
        path: '/roles',
        code: 'roles',
        component: Roles,
        icon: 'roles',
    },
    {
        path: '/roles/:roleName',
        code: 'edit_role',
        component: EditRole,
    },
    {
        path: '/roles/new',
        code: 'create_role',
        component: CreateRole,
    },
    {
        path: '/users',
        code: 'users',
        component: Users,
        icon: 'users',
    },
    {
        path: '/users/:userId',
        code: 'edit_user',
        component: EditUser,
    },
    {
        path: '/users/new',
        code: 'create_user',
        component: CreateUser,
    },
    {
        path: '/news',
        code: 'news',
        component: News,
        icon: 'news',
    },
    {
        path: '/news/:id',
        code: 'edit_news',
        component: EditNews,
    },
    {
        path: '/news/new',
        code: 'create_news',
        component: CreateNews,
    },
    {
        path: `/${ROUTE_CONTENT}`,
        code: 'content',
        component: Content,
        icon: 'content',
    },
    {
        path: `/${ROUTE_CONTENT}/:id`,
        code: 'content_with_mode',
        component: Content,
    },
    {
        path: `/${ROUTE_CONTENT}/${MODE_WEB_CONTENT}/${ACTION_NEW}`,
        code: 'web_content_new',
        component: CreateWebContent,
    },
    {
        path: `/${ROUTE_CONTENT}/${MODE_WEB_CONTENT}/:id`,
        code: 'web_content_new_edit',
        component: EditWebContent,
    },
    {
        path: `/${ROUTE_CONTENT}/${MODE_WEB_FOOTER_CONTENT}/${ACTION_NEW}`,
        code: 'web_footer_content_new',
        component: CreateWebFooterContent,
    },
    {
        path: `/${ROUTE_CONTENT}/${MODE_WEB_FOOTER_CONTENT}/:id`,
        code: 'web_footer_content_edit',
        component: EditWebFooterContent,
    },
    {
        path: '/dashboard',
        code: 'dashboard',
        component: Dashboard,
        icon: 'dashboard',
    },
];

export default RouteList;
