export const ROUTE_CONTENT = 'content';
export const MODE_WEB_FOOTER_CONTENT = 'web_footer_content';
export const MODE_WEB_CONTENT = 'web_content';
export const ACTION_NEW = 'new';
export const WEB_FOOTER_CONTENT_TYPE_LINK = 'link';
export const WEB_FOOTER_CONTENT_TYPE_TEXT = 'text';
export const WEB_FOOTER_CONTENT_TYPE_HTML = 'html';
export const CONTENT_TYPES = [
    {
        value: WEB_FOOTER_CONTENT_TYPE_TEXT,
        label: 'Текст',
    },
    {
        value: WEB_FOOTER_CONTENT_TYPE_LINK,
        label: 'Посилання',
    },
    {
        value: WEB_FOOTER_CONTENT_TYPE_HTML,
        label: 'HTML',
    },
];