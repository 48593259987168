import { Button, Form, message, Spin } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { NamePath } from 'antd/lib/form/interface';
import moment from 'moment';
import { FC } from 'react';
import { CreateUpdateRequestModel } from '../../../models/RequestModel';
import RequestForm from './RequestForm';

interface CreateRequestFormProps {
    isLoading: boolean;
    viewMode?: boolean;
    onSubmitValues: (data: CreateUpdateRequestModel) => any;
}

const CreateRequestForm: FC<CreateRequestFormProps> = ({ isLoading, viewMode = true, onSubmitValues }) => {
    const [form] = useForm<CreateUpdateRequestModel>();
    const useFormWatch = (name: NamePath) => useWatch(name, form);

    const onFinish = (values: CreateUpdateRequestModel) => {
        const finalRequest: CreateUpdateRequestModel = {
            ...values,
            citizenship: !(values.citizenship as any)?.code ? undefined : values.citizenship,
            gender: !(values.gender as any)?.code ? undefined : values.gender,
            lookingForCategory: !(values.lookingForCategory as any)?.code ? undefined : values.lookingForCategory,
            lookingForType: !(values.lookingForType as any).code ? undefined : values.lookingForType,
            city: !(values.city as any)?.id ? undefined : values.city,
            region: !(values.region as any)?.id ? undefined : values.region,
            lossCity: !(values.lossCity as any)?.id ? undefined : values.lossCity,
            lossRegion: !(values.lossRegion as any)?.id ? undefined : values.lossRegion,
            militaryFormation: !(values.militaryFormation as any)?.code ? undefined : values.militaryFormation,
            militaryRank: !(values.militaryRank as any)?.code ? undefined : values.militaryRank,
            physicalCondition: !(values.physicalCondition as any)?.code ? undefined : values.physicalCondition,
            type: !(values.type as any)?.code ? undefined : values.type,
            ...values.dateOfBirth ? {dateOfBirth: new Date(moment(values.dateOfBirth).format('YYYY-MM-DD'))} : {},
            ...values.dateOfLossCommunication ? {dateOfLossCommunication: new Date(moment(values.dateOfLossCommunication).format('YYYY-MM-DD'))} : {},
        };
        onSubmitValues(finalRequest)
            .unwrap()
            .then(() => {
                message.success('Запит створено!');
                form.resetFields();
                window.scrollTo(0, 0);
            })
            .catch((error: any) => {
                message.error(error.data.message);
            });
    };

    return (
        <Spin spinning={isLoading}>
            <Form<CreateUpdateRequestModel>
                form={form}
                labelCol={{ span: 6 }}
                disabled={viewMode}
                layout="horizontal"
                onFinish={onFinish}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                Створити запит
                <RequestForm request={null} onSubmitValues={onFinish} formWatch={useFormWatch} viewMode={viewMode} />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default CreateRequestForm;
