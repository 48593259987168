import { Button, Card, Col, Form, Input, notification, Row, Spin } from 'antd';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginUserMutation } from '../redux/api/authApi';
import { getChangePasswordData, getUser } from '../redux/features/authSlice';
import { useAppSelector } from '../redux/hooks';

interface LoginData {
    email: string;
    password: string;
}

const Login: FC = () => {
    const [loginUser, { isLoading, error }] = useLoginUserMutation();

    const onFinish = (values: LoginData) => {
        loginUser(values);
    };

    const navigate = useNavigate();

    const user = useAppSelector(getUser);
    useEffect(() => {
        if(user) {
            navigate('/');
        }
    }, [user, navigate]);

    const changePassworData = useAppSelector(getChangePasswordData);
    useEffect(() => {
        if(changePassworData) {
            navigate('/change-password')
        }
    }, [changePassworData, navigate]);

    useEffect(() => {
        if(error && 'data' in error) {
            notification.error({
                message: (error.data as any).message,
            })
        }
    }, [error]);

    return (
        <Row justify="center" align="middle" style={{ height: '100%' }}>
            <Col>
            <Spin spinning={isLoading}>
                <Card style={{ width: '400px' }}>
                    <Form<LoginData>
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        autoComplete="off">
                        <Form.Item
                            label="Ім’я користувача"
                            name="email"
                            rules={[{ required: true, message: `Обов'язкове поле!` }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Пароль"
                            name="password"
                            rules={[{ required: true, message: `Обов'язкове поле!` }]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Ввійти
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
                </Spin>
            </Col>
        </Row>
    );
};

export default Login;
