import React from 'react';
import { useGetWebFooterContentQuery } from '../../redux/api/webFooterContentApi';
import { Button, Col, Row, Table } from 'antd';
import { Link } from 'react-router-dom';
import { ACTION_NEW, MODE_WEB_FOOTER_CONTENT, ROUTE_CONTENT, CONTENT_TYPES } from '../../constants/ContentConstants';
import { ColumnsType } from 'antd/lib/table';
import { WebFooterContentModel } from '../../models/WebFooterContentModel';
import moment from 'moment';
import {useGetWFCCategoryQuery} from "../../redux/api/webFCCategoryApi";
import {WebFCCategoryModel} from "../../models/WebFCCategoryModel";

const getColumns = (categories?: WebFCCategoryModel[]): ColumnsType<WebFooterContentModel> => [
    {
        title: 'Номер',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Заголовок',
        dataIndex: 'translations',
        key: 'title',
        render: (value: any, record) => (
            <Link to={`/${ROUTE_CONTENT}/${MODE_WEB_FOOTER_CONTENT}/${record.id}`}>{value?.[0]?.content ?? ''}</Link>
        ),
    },
    {
        title: 'Категорія',
        dataIndex: 'category',
        key: 'code',
        render: (value: WebFooterContentModel['category']) => {
            const result = categories?.find( cat => cat.code === value?.code)?.translations.find(item => item.lang.code === 'uk');
            return result ? result.description : value?.code;
        },
    },
    {
        title: 'Тип',
        dataIndex: 'type',
        key: 'link',
        render: (value: string) => {
            const result = CONTENT_TYPES.find(type => type.value === value);
            return result ? result.label : value;
        }
    },
    {
        title: 'Дата публікації',
        dataIndex: 'category',
        key: 'createdDate',
        render: (value: WebFooterContentModel['category']) => moment(value?.createdDate).format('DD.MM.YYYY'),
    },
];
const TabWebFooterContent = () => {
    const { data, isLoading, isFetching } = useGetWebFooterContentQuery();
    const { data: categories, isLoading: isCategoryLoading } = useGetWFCCategoryQuery();

    const collection = data ?? [];

    return (
        <Row>
            <Col span={24}>
                <Row justify="end">
                    <Link to={`/${ROUTE_CONTENT}/${MODE_WEB_FOOTER_CONTENT}/${ACTION_NEW}`}>
                        <Button type="primary">Додати</Button>
                    </Link>
                </Row>
            </Col>
            <Table<any>
                className="main-table"
                rowKey="id"
                loading={isLoading || isFetching || isCategoryLoading}
                columns={getColumns(categories)}
                dataSource={collection}
            />
        </Row>
    );
};

export default TabWebFooterContent;
