import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './api/authApi';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import rootReducer from './reducers';
import { requestApi } from './api/requestApi';
import { addressApi } from './api/addressApi';
import { dictionariesApi } from './api/dictionariesApi';
import { filesApi } from './api/filesApi';
import { usersApi } from './api/usersApi';
import { rolesApi } from './api/rolesApi';
import { newsApi } from './api/newsApi';
import { webContentApi } from './api/webContentApi';
import { webFooterContentApi } from './api/webFooterContentApi';
import { webFCCategoryApi } from './api/webFCCategoryApi';


export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }
  }).concat([
    authApi.middleware,
    requestApi.middleware,
    addressApi.middleware,
    dictionariesApi.middleware,
    filesApi.middleware,
    usersApi.middleware,
    rolesApi.middleware,
    newsApi.middleware,
    webContentApi.middleware,
    webFooterContentApi.middleware,
    webFCCategoryApi.middleware,
  ]),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;