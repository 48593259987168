import { FC } from 'react';
import FormPageLayout from '../../components/common/FormPageLayout';
import CreateRequestForm from '../../components/forms/requst-form/CreateRequestForm';
import { useCreateRequestMutation } from '../../redux/api/requestApi';

const CreateRequest: FC = () => {
    const [createRequest, { isLoading: isCreate }] = useCreateRequestMutation();

    return (
        <FormPageLayout>
            <CreateRequestForm
                isLoading={isCreate}
                viewMode={false}
                onSubmitValues={createRequest}
            />
        </FormPageLayout>
    );
};

export default CreateRequest;
