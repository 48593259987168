import { Button, Col, Row, Space, Table } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/lib/table';
import moment from 'moment';
import { QueryOptions } from 'odata-query';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { RequestFiltersFields, RequestFiltersModel, RequestModel } from '../../models/RequestModel';
import { useDownloadRequestMutation, useGetRequestsQuery } from '../../redux/api/requestApi';
import RequestFilters from './components/RequestFilters';
import { getContainFilterValue } from '../../helpers';
import { getFullNameFilterValue } from '../../helpers/getFullNameFilterValue';

const MAX_TABLE_WIDTH = 1400;

const getColumns = (pathname: string): ColumnsType<RequestModel> => [
    {
        title: 'Номер',
        dataIndex: 'id',
        key: 'id',
        // TODO: next iteration
        render: (id) => <Link to={`${pathname}/${id}`} >{id}</Link>,
        width: 20,
    },
    {
        title: 'ПІБ розшукованої особи',
        dataIndex: 'name',
        render: (value: any, row: RequestModel) => `${row.surname} ${row.name} ${row.patronymic}`,
        width: 150,
    },
    {
        title: 'Дата народження',
        dataIndex: 'dateOfBirth',
        key: 'dateOfBirth',
        render: (value: string) => (value ? moment(value).format('DD.MM.YYYY') : ''),
        width: 50,
    },
    {
        title: 'ПІБ заявника',
        dataIndex: 'applicantName',
        render: (value: any, row: RequestModel) =>
            `${row.applicant?.surname || ''} ${row.applicant?.name || ''} ${row.applicant?.patronymic || ''}`,
        width: 150,
    },
    {
        title: 'Контакти заявника',
        dataIndex: 'applicanContacts',
        render: (value: any, row: RequestModel) => (
            <div>
                <span>{row.applicant?.phone || ''}</span>
                <br />
                <span>{row.applicant?.email || ''}</span>
            </div>
        ),
        width: 150,
    },
    {
        title: 'Дата',
        dataIndex: 'createdDate',
        key: 'createdDate',
        render: (value: string) => moment(value).format('DD.MM.YYYY'),
        width: 50,
    },
    {
        title: 'Статус звернення',
        dataIndex: ['status', 'translations', 0, 'description'],
        key: 'status',
        width: 50,
    },
];

const getTopSkip = (pageSize: number = 0, currentPage: number = 0) => {
    const top = pageSize;
    const skip = (currentPage - 1) * pageSize;
    return { top, skip };
};

const defaultRequestQuery = {
    orderBy: 'createdDate desc',
    count: true,
};

const getRequestFiltersFromFormValues = (filters: RequestFiltersFields): any => {
    const { status, phone, missing, applicant } = filters;
    const result: any = { and: [] };

    result.or = status?.map((status) => ({ status }));
    if (phone) {
        result.and.push({
            applicant: { or: [{ phone: getContainFilterValue(phone) }, { secondPhone: getContainFilterValue(phone) }] },
        });
    }
    if (missing) {
        result.and.push(getFullNameFilterValue(missing));
    }
    if (applicant) {
        result.and.push({
            applicant: {
                and: getFullNameFilterValue(applicant),
            },
        });
    }
    return result;
};

const Register: FC = () => {
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });

    const [filters, setFilters] = useState<RequestFiltersModel>({});
    const handleFilters = (filters: RequestFiltersFields) => {
        setPagination((currentPagination) => ({ ...currentPagination, current: 1 }));
        setFilters(getRequestFiltersFromFormValues(filters));
    };

    const queryData = useMemo<Partial<QueryOptions<RequestModel>>>(
        () => ({
            ...defaultRequestQuery,
            ...getTopSkip(pagination.pageSize, pagination.current),
            filter: filters,
        }),
        [pagination, filters],
    );

    const { data, isLoading, isFetching, refetch } = useGetRequestsQuery(queryData);
    const requests = data?.items || [];
    const count = data?.count;

    const [downloadRequest, { isLoading: downloadLoading }] = useDownloadRequestMutation();

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    const { pathname } = useLocation();

    const handleTableChange: TableProps<RequestModel>['onChange'] = (newPagination, _filters, _sorter) => {
        setPagination(newPagination);
    };

    return (
        <Row>
            <Col span={24}>
                <Row justify="end">
                    <Space>
                        <Button loading={downloadLoading} onClick={downloadRequest}>
                            Експорт
                        </Button>
                        {/* // TODO: next iteration set disabled false */}
                        <Link to={`${pathname}/new`}>
                            <Button type="primary" disabled={true}>
                                Створити заявку
                            </Button>
                        </Link>
                    </Space>
                </Row>
            </Col>
            <Col span={24}>
                <RequestFilters setFilters={handleFilters} />
            </Col>
            <Table<RequestModel>
                className="main-table"
                rowKey="id"
                loading={isLoading || isFetching}
                columns={getColumns(pathname)}
                dataSource={requests}
                pagination={pagination}
                onChange={handleTableChange}
                scroll={{ x: MAX_TABLE_WIDTH }}
            />
        </Row>
    );
};

export default Register;
