import { Button, Form, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FC, useMemo } from 'react';
import { RoleModel } from '../../../models/RoleModel';
import { useGetMenuFunctionsQuery } from '../../../redux/api/usersApi';
import RoleDataFields from './RoleDataFields';
import { MenuFunctionModel } from '../../../models/MenuFunctionModel';
import { QueryOptions } from 'odata-query';

interface RoleFormProps {
    isLoading: boolean;
    onSubmitValues: (data: RoleModel) => void;
}

const CreateUserForm: FC<RoleFormProps> = ({ isLoading, onSubmitValues }) => {
    const [form] = useForm<RoleModel>();

    const onFinish = (values: RoleModel) => {
        const finalUser: RoleModel = {
            ...values,
            menuFunctions:
                values?.menuFunctions && values?.menuFunctions.length > 0
                    ? values.menuFunctions.map((item: any) => ({ id: item } as MenuFunctionModel))
                    : null,
        };
        onSubmitValues(finalUser);
    };

    return (
        <Spin spinning={isLoading}>
            <Form<RoleModel>
                form={form}
                onFinish={onFinish}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                <RoleDataFields />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default CreateUserForm;
