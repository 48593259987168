import { Button, Form, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FC, useEffect } from 'react';
import { RoleModel } from '../../../models/RoleModel';
import { UpdateUserModel } from '../../../models/UserModel';
import UserDataFields from './UserDataFields';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

interface UserFormProps {
    user: UpdateUserModel | null;
    isLoading: boolean;
    onSubmitValues: (data: UpdateUserModel) => void;
}

interface UserForm {
    locked: boolean;
}

interface UserFormValues extends Omit<UpdateUserModel, 'id'>, UserForm {}

const UpdateUserForm: FC<UserFormProps> = ({ isLoading, user, onSubmitValues }) => {
    const [form] = useForm<UserFormValues>();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                ...user,
                roles: user?.roles ? (user.roles as RoleModel[]).map((item: RoleModel) => item.name) : [],
                locked: !!user.lockedDate
            });
        }
    }, [user, form]);

    const onFinish = (values: UserFormValues) => {
        if (user) {
            const { locked, ...rest } = values;
            const finalUser: UpdateUserModel = {
                ...rest,
                id: user.id,
                roles: (rest.roles as string[])?.map((item: string) => ({ name: item })) || [],
                lockedDate: locked ? moment().format('YYYY-MM-DD') : null,
            };
            onSubmitValues(finalUser);
            navigate('/users');
        }
    };

    return (
        <Spin spinning={isLoading}>
            <Form<UserFormValues>
                form={form}
                onFinish={onFinish}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                <UserDataFields disableEmail lockedDate={user?.lockedDate} />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default UpdateUserForm;
