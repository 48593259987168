import { BackTop } from 'antd';
import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.less';
import MainLayout from './components/common/MainLayout';
import PrivateRoute from './components/common/PrivateRoute';
import ChangePassword from './pages/ChangePassword';
import Login from './pages/Login';

const App: FC = () => (
    <div className="App">
        <BackTop />
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route
                path="*"
                element={
                    <PrivateRoute>
                        <MainLayout />
                    </PrivateRoute>
                }
            />
        </Routes>
    </div>
);

export default App;
