import { Card, Col, Row } from 'antd';
import { FC, ReactNode } from 'react';
import BackButton from './BackButton';

interface FormPageLayoutProps {
    children: ReactNode;
    backButtonLabel?: ReactNode;
    actions?: ReactNode;
}

const FormPageLayout: FC<FormPageLayoutProps> = ({ children, backButtonLabel, actions }) => {
    return (
        <>
            <Row>
                <Col span={12}>
                    {backButtonLabel && <BackButton>{backButtonLabel}</BackButton>}
                </Col>
                {actions && <Col span={12} style={{ textAlign: 'end' }}>{actions}</Col>}
            </Row>
            <Row justify="center">
                <Card bordered={false} bodyStyle={{ minWidth: '800px' }}>
                    {children}
                </Card>
            </Row>
        </>
    );
};

export default FormPageLayout;
