import React, { FC, useCallback, useEffect } from 'react';
import WebFooterContentDataFields from './WebFooterContentDataFields';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { WebFooterContentModel } from '../../../models/WebFooterContentModel';
import { Button, Col, Form, Row, Spin } from 'antd';
import { WebFCCategoryModel } from '../../../models/WebFCCategoryModel';
import { WEB_FOOTER_CONTENT_TYPE_LINK } from '../../../constants/ContentConstants';
import { useParams } from 'react-router-dom';
interface EditWFContentFormProps {
    isLoading: boolean;
    onSubmit: (data: WebFooterContentModel) => void;
    onDelete: (id: string) => void;
    data: WebFooterContentModel | null;
    categories?: WebFCCategoryModel[];
}

const EditWebFooterContentForm: FC<EditWFContentFormProps> = ({ isLoading, onSubmit, onDelete, data, categories }) => {
    const { id } = useParams();
    const [form] = useForm<WebFooterContentModel>();
    const type = useWatch('type', form);
    const onFinish = useCallback(() => {
        const { translations, ...rest } = form.getFieldsValue();
        const finalContent: WebFooterContentModel = {
            id,
            ...rest,
            translations: Object.values(translations).map((item) => ({
                ...item,
                link: type === WEB_FOOTER_CONTENT_TYPE_LINK ? item.link : undefined,
            })),
        };
        onSubmit(finalContent);
    }, [form, type]);

    useEffect(() => {
        if (data && form) {
            const { category, ...rest } = data;
            form.setFieldsValue({
                category: category?.code,
                ...rest,
            } as WebFooterContentModel);
        }
    }, [data, form]);

    return (
        <Spin spinning={isLoading}>
            <Form<WebFooterContentModel>
                form={form}
                onFinish={onFinish}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                <Col span={24}>
                    <Row justify="end">
                        <Button onClick={() => onDelete(form.getFieldValue('id'))} type="primary">
                            Видалити контент
                        </Button>
                    </Row>
                </Col>
                <WebFooterContentDataFields
                    categories={categories}
                    getFieldValue={form.getFieldValue}
                    type={type}
                    translations={data?.translations}
                />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default EditWebFooterContentForm;
