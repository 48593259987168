import React, { FC, useCallback } from 'react';
import WebFooterContentDataFields from './WebFooterContentDataFields';
import { WebFooterContentModel } from '../../../models/WebFooterContentModel';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { Button, Form, Spin } from 'antd';
import { WebFCCategoryModel } from '../../../models/WebFCCategoryModel';
import { WEB_FOOTER_CONTENT_TYPE_LINK } from '../../../constants/ContentConstants';

interface CreateWFContentFormProps {
    isLoading: boolean;
    categories?: WebFCCategoryModel[];
    onSubmit: (data: WebFooterContentModel) => void;
}

const CreateWebFooterContentForm: FC<CreateWFContentFormProps> = ({ isLoading, categories, onSubmit }) => {
    const [form] = useForm<WebFooterContentModel>();
    const type = useWatch('type', form);
    const onFinish = useCallback(() => {
        const { translations, ...rest } = form.getFieldsValue();
        const finalContent: WebFooterContentModel = {
            ...rest,
            translations: Object.values(translations).map((item) => ({
                ...item,
                link: type === WEB_FOOTER_CONTENT_TYPE_LINK ? item.link : undefined,
            })),
        };
        onSubmit(finalContent);
    }, [form, type]);

    return (
        <Spin spinning={isLoading}>
            <Form<WebFooterContentModel>
                form={form}
                onFinish={onFinish}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                <WebFooterContentDataFields categories={categories} getFieldValue={form.getFieldValue} type={type} />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default CreateWebFooterContentForm;
