import { Button, Col, Image, Row, Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { QueryOptions } from 'odata-query';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getTopSkip } from '../../helpers';
import { NewsModel, NewsTranslationModel } from '../../models/NewsModel';
import { FileMetaDataModel, useLazyGetFileDataByIdQuery } from '../../redux/api/filesApi';
import { useGetNewsQuery } from '../../redux/api/newsApi';

const BASE_URL = process.env.REACT_APP_BASE_URL as string;

const getColumns = (pathname: string): ColumnsType<NewsModel> => [
    {
        title: 'Картинка',
        dataIndex: 'image',
        key: 'image',
        render: (id: string) => <Image width={100} src={`${BASE_URL}storage/files/${id}`} />,
    },
    {
        title: 'Заголовок',
        dataIndex: 'title',
        render: (name: string, record) => {
            const { translations = [] } = record;
            const ukTranslation = translations.find((item: NewsTranslationModel) => item.lang?.code === 'uk');
            return <Link to={`${pathname}/${record.id}`}>{ukTranslation?.title}</Link>;
        },
    },
    {
        title: 'Дата публікації',
        dataIndex: 'date',
        key: 'date',
        render: (value: string) => moment(value).format('DD.MM.YYYY'),
    },
    {
        title: 'Опубліковано',
        dataIndex: 'published',
        key: 'published',
        render: (value: boolean) => (value ? 'Так' : 'Ні'),
    },
];

const defaultUserQuery = {
    orderBy: 'date desc',
    count: true,
};

const News: FC = () => {
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });

    const queryData = useMemo<Partial<QueryOptions<NewsModel>>>(
        () => ({
            ...defaultUserQuery,
            ...getTopSkip(pagination.pageSize, pagination.current),
            // filter: filters,
        }),
        [pagination /* , filters */],
    );

    const [triggerGetFileDataById] = useLazyGetFileDataByIdQuery();
    const getFilesDataFromIds = useCallback(
        async (fileIdArr: string[]) => {
            const filesPromiseArray = await Promise.allSettled(
                fileIdArr.map((id) => triggerGetFileDataById(id).unwrap()),
            );
            const files: FileMetaDataModel[] = [];
            filesPromiseArray.forEach((settledPromiseFile) => {
                if (settledPromiseFile.status === 'fulfilled') {
                    files.push(settledPromiseFile.value);
                } else {
                    console.error(settledPromiseFile.reason.data?.message || 'Error while getting file data');
                }
            });
            return files;
        },
        [triggerGetFileDataById],
    );

    const { data, isLoading, isFetching } = useGetNewsQuery(queryData);
    const news = data?.items;
    const count = data?.count;

    const { pathname } = useLocation();

    useEffect(() => {
        setPagination((currentPagination) => ({ ...currentPagination, total: count }));
    }, [count]);

    useEffect(() => {
        if (news && news.length > 0) {
            const imageIds = news.map((item: NewsModel) => item.image as string);
            getFilesDataFromIds(imageIds).then((files) => {
                // TODO: TBD
                console.log('>>> FILES -> ', files);
            });
        }
    }, [news]);

    const handleTableChange: TableProps<NewsModel>['onChange'] = (newPagination, _filters, _sorter) => {
        setPagination(newPagination);
    };

    return (
        <Row>
            <Col span={24}>
                <Row justify="end">
                    <Link to={`${pathname}/new`}>
                        <Button type="primary">Створити новину</Button>
                    </Link>
                </Row>
            </Col>
            {/* <Col span={24}>
                <UserFilters setFilters={handleFilters} />
            </Col> */}
            <Table<NewsModel>
                className="main-table"
                rowKey="id"
                loading={isLoading || isFetching}
                columns={getColumns(pathname)}
                dataSource={news}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </Row>
    );
};

export default News;
