import { Button, Col, Row, Table, Tag } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/lib/table';
import { QueryOptions } from 'odata-query';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getContainFilterValue, getTopSkip } from '../../helpers';
import { UserFiltersFields, UserFiltersModel, UserModel } from '../../models/UserModel';
import { useGetUsersQuery } from '../../redux/api/usersApi';
import UserFilters from './components/UserFilters';
import moment from 'moment';

const getColumns = (pathname: string): ColumnsType<UserModel> => ([
    {
        title: 'Ім’я користувача',
        dataIndex: 'email',
        key: 'email',
        render: (email: string, record) => <Link to={`${pathname}/${record.id}`} >{email}</Link>
    },
    {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: 'Прізвище',
        dataIndex: 'lastName',
        key: 'lastName',
    },
    {
        title: 'Ім’я',
        dataIndex: 'firstName',
        key: 'firstName',
    },
    {
        title: 'Заблоковано',
        dataIndex: 'lockedDate',
        key: 'lockedDate',
        render: (value: string) => {
            if (!value)
                return '';
            return (
                <Tag color='volcano'>{moment(value).format('DD.MM.YYYY')}</Tag>
            );
        },
    },
]);

const defaultUserQuery = {
    orderBy: 'createdDate desc',
    count: true,
}

const getUserFiltersFromFormValues = (filters: UserFiltersFields): UserFiltersModel => ({
    'tolower(email)': getContainFilterValue(filters.email),
    'tolower(phone)': getContainFilterValue(filters.phone),
    'tolower(firstName)': getContainFilterValue(filters.firstName),
    'tolower(lastName)': getContainFilterValue(filters.lastName),
});

const Users: FC = () => {
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
    });

    const [filters, setFilters] = useState<UserFiltersModel>({});
    const handleFilters = (filters: UserFiltersFields) => {
        setPagination(currentpagination => ({ ...currentpagination, current: 1 }));
        setFilters(getUserFiltersFromFormValues(filters));
    }

    const queryData = useMemo<Partial<QueryOptions<UserModel>>>(() => ({
        ...defaultUserQuery,
        ...getTopSkip(pagination.pageSize, pagination.current),
        filter: filters,
    }), [pagination, filters]);

    const { data, isLoading, isFetching, refetch } = useGetUsersQuery(queryData);
    const requests = data?.items;
    const count = data?.count;

    useEffect(() => {
        refetch();
    }, [])

    useEffect(() => {
        setPagination(currentPagination => ({ ...currentPagination, total: count }))
    }, [count]);

    const { pathname } = useLocation();

    const handleTableChange: TableProps<UserModel>['onChange'] = (newPagination, _filters, _sorter) => {
        setPagination(newPagination);
    }

    return (
        <Row>
            <Col span={24}>
                <Row justify="end">
                    <Link to={`${pathname}/new`}>
                        <Button type="primary">Створити користувача</Button>
                    </Link>
                </Row>
            </Col>
            <Col span={24}>
                <UserFilters setFilters={handleFilters} />
            </Col>
            <Table<UserModel>
                className="main-table"
                rowKey="id"
                loading={isLoading || isFetching}
                columns={getColumns(pathname)}
                dataSource={requests}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </Row>
    )
};

export default Users;
