import { Button, Form, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuFunctionModel } from '../../../models/MenuFunctionModel';
import { RoleModel } from '../../../models/RoleModel';
import RoleDataFields from './RoleDataFields';

interface RoleFormProps {
    role: RoleModel | null;
    isLoading: boolean;
    onSubmitValues: (data: RoleModel) => void;
}

const UpdateUserForm: FC<RoleFormProps> = ({ isLoading, role, onSubmitValues }) => {
    const [form] = useForm<RoleModel>();
    const navigate = useNavigate();

    useEffect(() => {
        if (role) {
            form.setFieldsValue({
                ...role,
                menuFunctions: role?.menuFunctions ? role?.menuFunctions.map((item: MenuFunctionModel) => item.id) : [],
            } as RoleModel);
        }
    }, [role, form]);

    const onFinish = (values: RoleModel) => {
        if (role) {
            const finalUser: RoleModel = {
                ...values,
                menuFunctions:
                    values?.menuFunctions && values?.menuFunctions.length > 0
                        ? values.menuFunctions.map((item: any) => ({ id: item } as MenuFunctionModel))
                        : null,
            };
            onSubmitValues(finalUser);
            navigate('/roles', { replace: true });
        }
    };

    return (
        <Spin spinning={isLoading}>
            <Form<RoleModel>
                form={form}
                onFinish={onFinish}
                validateMessages={{
                    required: 'Обов’язкове поле!',
                }}>
                <RoleDataFields disableName />
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Зберегти
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default UpdateUserForm;
