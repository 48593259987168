import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Space, UploadFile } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { FC, useEffect, useState } from 'react';
import { CreateUpdateRequestModel, RequestModel } from '../../../models/RequestModel';
import {
    useGetCitiesQuery,
    useGetCountriesQuery,
    useGetGenderQuery,
    useGetLookingForCategoriesQuery,
    useGetLookingForTypesQuery,
    useGetMilitaryFormationQuery,
    useGetMilitaryRankQuery,
    useGetPhysicalConditionsQuery,
    useGetRegionsQuery,
    useGetRequestTypesQuery,
} from '../../../redux/api/dictionariesApi';
import { useGetBulkFilesByIdsMutation } from '../../../redux/api/filesApi';
import RequestBlock from './RequestBlock';
import { RequestBlockModel, requestBlocks } from './RequestFormBlocks';

interface RequestFormProps {
    request: RequestModel | null;
    viewMode?: boolean;
    formWatch: (name: NamePath) => any;
    onSubmitValues: (data: CreateUpdateRequestModel) => void;
}

export interface UploadFileWithId<T = any> extends UploadFile<T> {
    id?: string;
}

const RequestForm: FC<RequestFormProps> = ({ request, viewMode = true, formWatch, onSubmitValues }) => {
    const [identifyFiles, setIdentifyFiles] = useState([]);
    const [documentsConfirmingFiles, setDocumentsConfirmingFiles] = useState([]);
    const { data: requestTypes } = useGetRequestTypesQuery();
    const { data: lookingForCategories } = useGetLookingForCategoriesQuery();
    const { data: lookingForTypes } = useGetLookingForTypesQuery();
    const { data: countries } = useGetCountriesQuery();
    const { data: gender } = useGetGenderQuery();
    const { data: militaryFormation } = useGetMilitaryFormationQuery();
    const { data: militaryRank } = useGetMilitaryRankQuery();
    const { data: physicalCondition } = useGetPhysicalConditionsQuery();
    const { data: regions } = useGetRegionsQuery();

    const formRegionValue = formWatch('region');
    const { data: cities } = useGetCitiesQuery(formRegionValue?.id ?? skipToken);

    const formLossRegionValue = formWatch('lossRegion');
    const { data: lossCities } = useGetCitiesQuery(formLossRegionValue?.id ?? skipToken);

    const [getFiles] = useGetBulkFilesByIdsMutation();
    useEffect(() => {
        if (request?.identifyFiles) {
            getFiles({ ids: request.identifyFiles })
                .unwrap()
                .then((res: any) => {
                    setIdentifyFiles(res);
                });
        }
    }, [request?.identifyFiles, getFiles]);

    useEffect(() => {
        if (request?.documentsConfirmingFiles) {
            getFiles({ ids: request.documentsConfirmingFiles })
                .unwrap()
                .then((res: any) => {
                    setDocumentsConfirmingFiles(res);
                });
        }
    }, [request?.documentsConfirmingFiles, getFiles]);

    const mapFields = (fields: any) => {
        if (typeof fields === 'function') {
            return fields();
        }
        return fields;
    };

    const options = {
        requestTypes,
        lookingForCategories,
        lookingForTypes,
        countries,
        gender,
        militaryFormation,
        militaryRank,
        physicalCondition,
        regions,
        cities,
        lossCities,
        identifyFiles,
        documentsConfirmingFiles,
        viewMode,
        formWatch,
    };

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            {requestBlocks(options).map((block: RequestBlockModel, index: number) => (
                <RequestBlock key={block.key} title={`${index + 1}. ${block.title}`}>
                    {mapFields(block.fields)}
                </RequestBlock>
            ))}
        </Space>
    );
};

export default RequestForm;
