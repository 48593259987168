import { combineReducers } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { addressApi } from './api/addressApi';
import { authApi } from './api/authApi';
import { dictionariesApi } from './api/dictionariesApi';
import { filesApi } from './api/filesApi';
import { newsApi } from './api/newsApi';
import { requestApi } from './api/requestApi';
import { rolesApi } from './api/rolesApi';
import { usersApi } from './api/usersApi';
import { webContentApi } from './api/webContentApi';
import { webFooterContentApi } from './api/webFooterContentApi';
import { webFCCategoryApi } from './api/webFCCategoryApi';
import authReducer from './features/authSlice';

const authPersistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    [requestApi.reducerPath]: requestApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [addressApi.reducerPath]: addressApi.reducer,
    [dictionariesApi.reducerPath]: dictionariesApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [webContentApi.reducerPath]: webContentApi.reducer,
    [webFooterContentApi.reducerPath]: webFooterContentApi.reducer,
    [webFCCategoryApi.reducerPath]: webFCCategoryApi.reducer,
})

export default rootReducer;